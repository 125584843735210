import { createApp } from 'vue'
import { createPinia } from 'pinia'

// Import base styles first
import '@/styles/global.scss'  // This will in turn @use _index.scss

import App from './App.vue'
import router from './router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import { 
    faAngleLeft, 
    faInfinity, 
    faTimesCircle, 
    faSpinner, 
    faCheckCircle, 
    faGlobe, 
    faQrcode, 
    faTriangleExclamation, 
    faWifi, 
    faSignOut, 
    faBuilding, 
    faUsers, 
    faBed, 
    faChevronRight, 
    faCaretRight, 
    faIdCard, 
    faCirclePlay, 
    faArrowRight,
    faCopy,
    faTimes,
    faPlus,
    faTrash
} from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(
    faAngleLeft, 
    faInfinity, 
    faTimesCircle, 
    faSpinner, 
    faCheckCircle, 
    faGlobe, 
    faQrcode, 
    faTriangleExclamation, 
    faWifi, 
    faSignOut, 
    faBuilding, 
    faUsers, 
    faBed, 
    faChevronRight, 
    faCaretRight, 
    faIdCard, 
    faCirclePlay, 
    faArrowRight,
    faCopy,
    faTimes,
    faPlus,
    faTrash
)

const app = createApp(App)

app.use(createPinia())
app.use(router)

/* add font awesome icon component */
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('font-awesome-layers', FontAwesomeLayers)

app.mount('#app')