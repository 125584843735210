import { defineStore } from 'pinia';
import algoliasearch from 'algoliasearch';
import type { SearchState, SearchResult } from '@/interfaces';

const searchClient = algoliasearch('LO1QWA976S', '3ffa5f7df2ad88d0ca45a7d290256840');

export const useSearchStore = defineStore({
  id: 'search',
  state: (): SearchState => ({
    searchResults: [],
    searchClient,
    indexes: {
      properties: searchClient.initIndex('properties'),
      reservations: searchClient.initIndex('reservations')
    }
  }),
  actions: {
    async search(query: string, options: any): Promise<void> {
      const filters = options?.filters || {};
      const index = options?.index ? this.indexes[options.index] : null;
  
      if (!index) throw new Error('No index specified');
  
      console.log("Searching index", index.indexName);
      console.log("Query", query);
      console.log("Filters", filters);
  
      const { hits } = await index.search(query, filters);
  
      console.log(hits);
  
      // Map hits to SearchResult to ensure type safety
      this.searchResults = hits.map(hit => ({
          objectID: hit.objectID,
          name: hit.name,
          logo: hit.logo,
          room: hit.room,
          first_name: hit.first_name,
          last_name: hit.last_name,
          booking_name: hit.booking_name,
          check_in_humanise: hit.check_in_humanise,
          check_out_humanise: hit.check_out_humanise,
          property_ref: hit.property_ref,
          loaded: false, // Default to false until explicitly marked
          hydration_plan_name: hit.hydration_plan_name,
          hydration_plan_names: hit.hydration_plan_names,
          occupants: hit.occupants || [],
      }));
    },
    clearSearchResults() {
        this.searchResults = [];
    },
  },
});
