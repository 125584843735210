<template>
    <div id="home-container">
        <SearchForm :title="title" :searchPlacholder="searchPlacholder" :filters="filters" :index="index" />
    </div>
</template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import SearchForm from "@/components/SearchForm.vue";
  
  export default defineComponent({
    components: {
        SearchForm
    },
    setup() {
      const title = "Let's find a refill point";
      const searchPlacholder = "Where are you?";
      const index: 'properties' | 'reservations' = 'properties'; 
      const filters = { facets: ["*"], facetFilters: [['active:true']] }
  
      return {
        title,
        searchPlacholder,
        index,
        filters
      };
    },
  });
  </script>

  
<style scoped lang="scss">

#home-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  padding: 20px;
}

</style>
  
