// apiEndpoints.ts
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { each } from 'lodash-es';

const isLocalhost = window.location.hostname === 'localhost';

const baseUrl = isLocalhost ? 'http://localhost:8829/api/v1' : 'https://h2os.aguakmzero.com/api/v1';

export const apiEndpoints = {
  getReservation: `${baseUrl}/reservations/get`,
  getObject: (collection: string, doc_id: string) => { return `${baseUrl}/object/${collection}/${doc_id}/get`; },
  findObjects: (collection: string) =>  {return `${baseUrl}/objects/${collection}/find`; },
  updateObject: (collection: string, doc_id: string) => { return `${baseUrl}/object/${collection}/${doc_id}/update`; },
  createUser: () => { return `${baseUrl}/auth/createUser`; },
  createUserWithEmailAndPassword: () => { return `${baseUrl}/auth/createUserWithEmailAndPassword`; },
  findUserByEmail: () => { return `${baseUrl}/auth/findUserByEmail`; },
};

// A method to do fetch requests 
export const fetcher = async (url: string, options: RequestInit = {}, payload: object | null = null) => {
  // Handle GET and POST requests

  let endpoint = url;

  if (options.method === 'POST') {
    options.headers = {
      'Content-Type': 'application/json',
    };
    if(payload) {
      options.body = JSON.stringify(payload);
    }
  } else if (options.method === 'GET') {
    options.headers = {
      'Content-Type': 'application/json',
    };

    endpoint = endpoint + "?";
    each(payload, (value, key) => {
      endpoint = endpoint + key + "=" + value + "&";
    });
  }

  const response = await fetch(endpoint, options);
  const data = await response.json();
  return data;
}
