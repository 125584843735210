<template>
  <Transition name="slide-fade">
    <div v-if="show" 
         class="notification" 
         :class="type"
         @click="hide">
      <div class="notification-content">
        <span class="message">{{ message }}</span>
        <button class="close-button" @click.stop="hide">
          <font-awesome-icon icon="times" />
        </button>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useNotificationStore } from '@/stores/notificationStore';

export default defineComponent({
  name: 'NotificationBubble',
  setup() {
    const notificationStore = useNotificationStore();
    return {
      message: computed(() => notificationStore.message),
      type: computed(() => notificationStore.type),
      show: computed(() => notificationStore.show),
      hide: notificationStore.hideNotification
    };
  },
});
</script>

<style scoped lang="scss">
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 300px;
  max-width: 400px;
  padding: 16px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  cursor: default;

  .notification-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .message {
    flex-grow: 1;
    font-size: 0.9rem;
    color: $kmzBlue;
  }

  .close-button {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    color: rgba($kmzBlue, 0.5);
    transition: color 0.2s ease;

    &:hover {
      color: $kmzBlue;
    }
  }

  &.success {
    border-left: 4px solid $kmzGreen;
  }

  &.error {
    border-left: 4px solid #dc3545;
  }

  &.warning {
    border-left: 4px solid #ffc107;
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
