<template>
    <div class="refill-pass">
      <div id="pass">
        <img :src="logoImage" class="pass-logo" width="200"/>
        <!-- <img :src="qrCodeUrl" alt="QR Code" class="qr" /> -->
        <div class="pass-pin"> {{  passPIN }}</div>
        <div class="pass_id"> {{ passID }} </div>
        <img :src="logoUrl" alt="Logo" class="prop-logo" />
      </div>
      <div id="save-pass-button" class="button-row" width="100vw">
        <button @click="downloadPassAsImage()" class="primary-button">Save your pass</button>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import html2canvas from 'html2canvas';
  import { apiEndpoints, fetcher } from '@/helpers/apiEndpoints';
  import { useRouter } from 'vue-router';
  import { getImageUrl, getProxyUrl } from '@/helpers/utils';
  import logoImage from '@/assets/logos/azulmarino.png'; // Importing logo image using the alias
  
  export default defineComponent({
    props: {
      type: {
        type: String,
        required: true,
      },
      pass_id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const logoUrl = ref('');
      const qrCodeUrl = ref('');
      const passID = ref('');
      const passPIN = ref('');
  
      onMounted(async () => {
        const router = useRouter();

        try {
          // fetch pass from API
          if(!props.type || !props.pass_id) {
            router.push({ name: 'search' });
          }

          const response = await fetcher(apiEndpoints.getObject(props.type, props.pass_id));

          if(response.error) {
            router.push({ name: 'search' });
          } else {
            // Generate QR code
            var reservation = response.data;
            const currentUrl = window.location.href;
            const qrCodeUrlRaw = `https://quickchart.io/qr?margin=0&size=1000&text=${encodeURIComponent(currentUrl)}`;
            const proxyUrl = getProxyUrl(encodeURIComponent(qrCodeUrlRaw));
            passID.value = reservation.reservation_id;
            qrCodeUrl.value = proxyUrl;
            passPIN.value = reservation.pin;

            if(reservation.property) {
              logoUrl.value = getImageUrl(reservation.property.logo, true); // true = use proxy

            }

          }
          
  
          
        } catch (error) {
          console.error(error);
          router.push({ path: '/search' });
        }
      });
  
      function downloadPassAsImage() {
        setTimeout(() => {
          const c: any = document.getElementById('pass');
          html2canvas(c).then((canvas: HTMLCanvasElement) => {
            const link = document.createElement('a');
            link.download = 'aguakmzero-pass.png';
            link.href = canvas.toDataURL('image/png');
            link.click();
          });
        }, 100);
      }
  
      return {
        logoImage,
        logoUrl,
        qrCodeUrl,
        passID,
        passPIN,
        downloadPassAsImage,
      };
    },
  });
  </script>
  
  <style lang="scss" scoped>
  
  #pass {
    background-color: white;
    width: 80%;
    margin: auto;
    border-radius: 3px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //margin-top: 10vh;



    .logo, .pass-logo {
        width: 50%;
        max-width: 300px;
        height: auto;
        position: relative;
    }
    
    .qr {
        width: 80%;
        height: auto;
        padding: 10px 0px;
        padding-bottom: 20px;

    }

    .pass-pin {
      font-weight: bold;
      font-size: 6rem;
      margin: 1rem 0;
    }

    .pass_id {
      font-weight: bold;
      font-size: 1rem;
    }

    .prop-logo {
        padding: 20px 0px;
        width: 70%;
        height: auto;
    }
  }

  .refill-pass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: auto;
    position: relative;

  }

    .button-row {
        width: 80%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
//        margin-bottom: 100px;
    }
  </style>
  