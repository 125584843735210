<template>
    <div class="users-admin">
      <h3 class="title is-2">Users Admin</h3>
      <div class="route-inner">
        <AddUser v-if="canAddUsers" :property_id="property_id" :client_id="client_id" />
      <div class="user-list">
        <div v-for="user in users" :key="user.uid" class="user-item">
          {{ user.first_name }} {{ user.last_name }} - {{ user.email }}
        </div>
      </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useUserStore } from '@/stores/user';
  import AddUser from '@/components/AddUser.vue';
  import { apiEndpoints, fetcher } from '@/helpers/apiEndpoints';
  import { getImageUrl } from '@/helpers/utils';
  import type { User, Property } from '@/interfaces';

    
  
  export default defineComponent({
    name: 'UsersAdmin',
    components: {
      AddUser,
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      const userStore = useUserStore();
      const property_id = ref(route.params.property_id);
      // define users as ref of type User
      const users = ref<User[]>([]);
      let property: any = ref({});

  
      // Redirect if property_id is not present
      watch(property_id, () => {
        if (!property_id.value) {
          router.push('/admin/property');
        }
      });

      const fetchProperty = async () => {
        console.log("getting property...", property_id.value);
        if(!property_id.value || property_id.value == "") return;
        const response = await fetcher(apiEndpoints.getObject("properties", property_id.value as string));
        
        if (response.error) {
          // Handle the error
          console.log(response.error);
        } else {
          var p = response.data;
          p.logo = getImageUrl(p.logo);
          property.value = p;

          console.log("property", property.value);
        }
      };
  
      const canAddUsers = userStore.isSuperuser || userStore.roles.client_admin;
  
      const fetchUsers = async () => {
        
  
        const client_id = property.value.client && property.value.client._documentPath ? property.value.client._documentPath.segments[1] : null;

        if(!userStore.isSuperuser && !client_id) return;
        
        let filters = [];

        if(!userStore.isSuperuser) {
          filters.push({
            field: 'client',
            operator: '==',
            value: { table: 'clients', id: client_id },
          });
        }

        console.log("fetching users...", filters);

        const response = await fetcher(apiEndpoints.findObjects('accounts'), {
          method: 'POST',
        }, {
          filters
        });
  
        if (response && response.data) {
          users.value = response.data;
        }
      };
  
      onMounted(async () => {
        await fetchProperty();
        fetchUsers();
      });
  
      return {
        property_id,
        users,
        canAddUsers,
        client_id: property.value.client,
      };
    },
  });
  </script>

  <style scoped lang="scss">
  .users-admin {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;

  @media (min-width: 768px) {
    gap: 2rem;
  }
}

.add-user {
  width: 50%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  button[type="submit"] {
    align-self: flex-end;
  }

  .error {
    color: red;
    font-size: 0.9rem;
  }
}

.users-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  @media (min-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.user-item {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s ease;
  width: 100%;
  font-size: 0.9rem;

  &:link, &:visited {
    color: #1a73e8;
    text-decoration: none;
  }

  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
  }
}

  </style>
  