<template>
  <div id="search-container">
    <div class="search-inner">
      <h2 v-if="title" class="title is-2">{{ title }}</h2>
      <div class="search-input">
        <input
          type="text"
          id="search-input"
          :placeholder="searchPlaceholder"
          v-model.trim="query"
          @input="handleSearchInput"
          @keydown.esc="clearSearchResults"
        />
        <div v-if="isLoading" class="loading-indicator">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <!-- Reservations Results -->
    <div 
      v-if="smallResults && index === 'reservations' && searchResults?.length"
      class="search-results small"
    >
      <div class="search-result header">
        <div class="room info col-1">Room</div>
        <div class="name info col-2">Name(s)</div>
        <template v-if="showDates">
          <div class="dates info col-2">Check in</div>
          <div class="dates info col-2">Check out</div>
        </template>
        <template v-if="showHydrationPlans" >
          <div class="info col-3">Hydration Plan(s)</div>
        </template>
      </div>
      
      <div 
        v-for="result in searchResults" 
        :key="result.objectID"
        class="search-result"
        @click="selectResult(result)"
      >
        <div class="room info col-1">{{ result.room }}</div>
        <div class="name info col-2">
          {{ result.booking_name }}
        </div>
        <template v-if="showDates">
          <div class="dates info col-2">{{ result.check_in_humanise }}</div>
          <div class="dates info col-2">{{ result.check_out_humanise }}</div>
        </template>
        <template v-if="showHydrationPlans" >
          <div class="info col-3">
            <span class="room">{{ result.hydration_plan_names }}</span>
          </div>
        </template>

      </div>
    </div>

    <!-- Default Results -->
    <div v-else class="search-results">
      <div 
        v-for="result in searchResults" 
        :key="result.objectID"
        class="search-result"
        :class="{ 'loaded': result.loaded }"
        @click="selectResult(result)"
      >
        <img 
          v-if="result.logo" 
          :src="getImageUrl(result.logo)" 
          class="logo"
          loading="lazy"
          :alt="result.name"
        />
        <div class="name">{{ result.name }}</div>
      </div>
    </div>

    <div v-if="query && query.length >= 3 && !isLoading && !searchResults?.length" class="no-results">
      No results found
    </div>
  </div>
</template>
  
<script lang="ts">
import { defineComponent, computed, watch, onMounted, ref, onUnmounted } from 'vue';
import type { PropType } from 'vue';
import { useSearchStore } from '@/stores/search';
import { getImageUrl } from '@/helpers/utils';
import { useRouter } from 'vue-router';
import type { SearchResult } from '@/interfaces';

type SearchIndex = 'properties' | 'reservations';

interface Props {
  title?: string;
  searchPlaceholder: string;
  index: SearchIndex;
  filters: Record<string, unknown>;
  smallResults: boolean;
  inputAlign: 'left' | 'center';
  showDates: boolean;
  showHydrationPlans: boolean;
}

export default defineComponent({
  name: 'SearchComponent',

  props: {
    title: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    index: {
      type: String as PropType<'properties' | 'reservations'>,
      default: 'properties',
    },
    filters: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
    smallResults: {
      type: Boolean,
      default: false,
    },
    inputAlign: {
      type: String as PropType<'left' | 'center'>,
      default: 'center',
      validator: (value: string): boolean => ['left', 'center'].includes(value),
    },
    showDates: {
      type: Boolean,
      default: true,
    },
    showHydrationPlans: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const searchStore = useSearchStore();
    const router = useRouter();
    const query = ref('');
    const isLoading = ref(false);
    const error = ref<string | null>(null);

    const allFilters = computed(() => ({
      ...props.filters,
    }));

    const searchResults = computed<SearchResult[]>(() =>
      searchStore.searchResults.map((result: SearchResult) => {
        const processedResult: SearchResult = {
          ...result,
          booking_name: `${result.first_name || ''} ${result.last_name || ''}`.trim(),
          hydration_plan_names: '',
          loaded: result.loaded || false,
          objectID: result.objectID,
        };

        if (result.occupants && result.occupants.length) {
          processedResult.booking_name = result.occupants
            .map(
              (occupant) =>
                `${occupant.first_name || ''} ${occupant.last_name || ''}`.trim()
            )
            .filter((name) => name)
            .join(', ');

          processedResult.hydration_plan_names = result.occupants
            .map((occupant) => occupant.hydration_plan_name)
            .filter(Boolean)
            .join(', ');
        }

        return processedResult;
      })
    );

    const handleSearchInput = async (): Promise<void> => {
      isLoading.value = true;
      try {
        if (query.value.length >= 3) {
          await searchStore.search(query.value, {
            index: props.index,
            filters: allFilters.value,
          });
        } else {
          searchStore.clearSearchResults();
        }
      } catch (err) {
        error.value = err instanceof Error ? err.message : 'Search failed';
      } finally {
        isLoading.value = false;
      }
    };

    const clearSearchResults = (): void => {
      searchStore.clearSearchResults();
      query.value = '';
      error.value = null;
    };

    const selectResult = (result: SearchResult): void => {
      const routes: Record<'properties' | 'reservations', () => void> = {
        properties: () => {
          router.push({
            name: 'property',
            params: { property_id: result.objectID },
          });
        },
        reservations: () => {
          if (!result.property_ref) {
            console.error('Property reference missing');
            return;
          }
          router.push({
            name: 'admin-reservations',
            params: {
              property_id: result.property_ref,
              reservation_id: result.objectID,
            },
          });
        },
      };

      const routeHandler = routes[props.index];
      if (routeHandler) {
        routeHandler();
      }
    };

    watch(
      searchResults,
      (newResults: SearchResult[]) => {
        if (!newResults?.length) return;

        newResults.forEach((result: SearchResult) => {
          if (!result.loaded) {
            setTimeout(() => {
              result.loaded = true;
            }, 300);
          }
        });
      },
      { deep: true }
    );

    onMounted(() => {
      clearSearchResults();
    });

    onUnmounted(() => {
      clearSearchResults();
    });

    return {
      query,
      searchResults,
      handleSearchInput,
      selectResult,
      clearSearchResults,
      isLoading,
      error,
      getImageUrl,
    };
  },
});

</script>
  

<style lang="scss" scoped>
#search-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  padding: 20px;

  .search-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    .search-input {
        background-color: white;
        border-radius: 3px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        margin: 10px;
        width: 80%;
        padding: 5px;
        text-align: center;

        // remove the outline when input is focused

        input {
            margin: 0px;
            width: 100%;
            padding: 10px 1rem;
            text-align: left;


            &:focus {
                outline: none;
            }

            &::placeholder {
                color: rgba($colorOneBlue, 0.4);
            }
        }

        &.left-search {
            input {
                text-align: left;
                padding-left: 25px;
            }
        }
    }
  }

  .search-results {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    padding: 10px;
    overflow-y: scroll;
    
    .search-result {
        background-color: white;
        border-radius: 3px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        margin: 10px;
        margin-bottom: 20px;
        padding: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateY(-20px);
        opacity: 0;
        transition: all 0.3s ease;

        .name {
            font-weight: bold;
            font-size: 1.2rem;
            margin: 10px 0px;
            text-align: center;
            width: 80%;
            margin-top: 20px;
        }

        .logo {
            width: 70%;
            height: auto;
            margin-top: 10px;
        }

        &.loaded {
            transform: translateY(0);
            opacity: 1;
        }

    }

    &.small {
      width: calc(80% - 20px);
      margin: auto;
      margin-top: -20px;
      box-shadow: 0px 25px 20px 0px rgba(black, 0.3);
      background-color: white;
      max-height: 40vh;
      margin-bottom: 50px;
      border-radius: 5px;
      padding: 10px;
      padding-top: 20px;
      display: flex;
      flex-direction: column;

      .search-result {
        margin: 5px;
        padding: 5px;
        color: rgba($colorOneBlue, 1);
        align-items: flex-start;
        flex-direction: row;
        opacity: 1;
        box-shadow: none;
        translate: none;
        transition-delay: all 0.3s ease;


        .info {
          font-size: 0.8rem;
          margin: 0px;
          width: 100%;
          display: flex;

          color: rgba($colorOneBlue, 0.7);

          &.col-1 {
            flex: 1;
          }

          &.col-2 {
            flex: 2;
          }

          &.col-3 {
            flex: 3;
            
          }

          &.name, &.dates {
            flex-grow: auto;
            text-transform: capitalize;
            text-align: left;
          }

          &.room {
            text-transform: uppercase;
            font-weight: bold;
          }

        }

        &:hover {
          background-color: rgba($colorTwoViolet, 0.2);
          transition-delay: all 0.3s ease;

        }

        &.header {
          display: flex;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 0px;

          .info {
            text-transform: uppercase;
            color: rgba($colorOneBlue, 0.6);
            cursor: default;
            font-size: 0.7rem;
          }
        }

      }

    }

  
  }

  .no-results {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    color: $kmzBlue;
  }

}
</style>