<template>
    <header v-if="!adminPages" class="header" :class="[headerStyle]">
        <Logo v-if="showLogo"/>
        <!-- <div class="sep"></div> -->
        <!-- <div class="collab-logo is-hidden">
            <img :src="OceanLogo" alt="Ocean 52" />
        </div> -->
    </header>
</template>

<script lang="ts">
    import { defineComponent, computed } from 'vue';
    import { useRoute } from 'vue-router';
    import Logo from '@/components/LogoComponent.vue';
    import OceanLogo from "@/assets/logos/ocean-52-logo.png";

    export default defineComponent({
        name: 'HeaderComponent',
        components: { Logo },
        setup() {
            const route = useRoute(); // Call useRoute once and store the reference

            // a computed property that decides whether logo is shown or not based on route
            const showLogo = computed(() => {
                return route.name !== 'pass';
            });

            const adminPages = computed(() => {
                return route.path.startsWith('/admin');
            });

            const headerStyle = computed(() => {
                switch(route.name) {
                    case "home":
                        return "home-header";
                    default:
                        return "default-header";
                }
            });

            return { showLogo, adminPages, headerStyle, OceanLogo };
        }
    });
</script>

<style lang="scss" scoped>
    header {
        width: 100%;
        display: flex;
        justify-content: center;
        max-width: $maxWidth;

        .logo, .collab-logo {
            height: 10vh;
            margin: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 100%;
            }
        }

        .collab-logo {
            display: none;

            img {
                height: 75%;
                filter: invert(-100%);
            }
        }

        .sep {
            width: 3px;
            height: 50%;
            background-color: $logoDarkColor;
            display: none;
            margin: 0px 15px;
        }

        &.home-header {
            padding-top: 3vh;
            padding-left: 4vw;
            justify-content: start;
            align-items: center;
            .collab-logo {
                display: flex;
            }

            .sep {
                display: block;
            }
        }
    }

    @media screen and (max-aspect-ratio: 1.2/1) {
        header {
            &.home-header {
                justify-content: center;
            }
        }
    }
</style>