import { defineStore } from 'pinia';
import { each } from 'lodash';
import { fetcher, apiEndpoints } from '@/helpers/apiEndpoints';
import { getImageUrl, getFirestoreId } from '@/helpers/utils';
import { useUserStore } from './user';
import type { PropertyState, AccessRules, Property } from '@/interfaces';

export const usePropertyStore = defineStore({
  id: 'property',

  state: (): PropertyState => ({
    properties: [],
    property: { 
      name: "", 
      logo: "", 
      client_property_ref: "", 
      client: null,
      id: "", 
      settings: {},
      access_rules: [] // Add access_rules to your state
    },
    hydrationPlans: [],
  }),

  getters: {
    // Add any getters if needed
    getProperty: (state) => (propertyId: string) => {
        return state.properties.find((p) => p.id === propertyId);
    },
    getSettings: (state) => (propertyId: string) => {
        const property = state.properties.find((p) => p.id === propertyId);
        return property ? property.settings : {};
    },
    getAccessRules: (state) => (propertyId: string) => { // Add a getter for access rules
      const property = state.properties.find((p) => p.id === propertyId);
      return property ? property.access_rules : [];
    },
    needsDates: (state) => (propertyId: string) => {
      const property = propertyId 
        ? state.properties.find(p => p.id === propertyId) 
        : null;
    
      if (!property || !property.settings?.reservation_settings) {
        console.log("No property or settings found");
        return true;
      }
   
      console.log("No dates", property.settings.reservation_settings.no_dates);
      return !(property.settings.reservation_settings.no_dates === true);
    },
    hasHydrationPlans: (state) => (propertyId: string) => {
      const property = propertyId 
        ? state.properties.find(p => p.id === propertyId) 
        : state.property;
    
      if (!property || !property.settings.reservation_settings) return false;
    
      return property.settings.reservation_settings.assign_hydration_plans !== false;
    },
    getHydrationPlan: (state) => (planId: string) => {
      return state.hydrationPlans.find((p) => p.id === planId);
    },
    getHydrationPlans: (state) => ({ clientId, propertyId }: { clientId?: string, propertyId?: string } = {}) => {
      if (propertyId) {
        const property = state.properties.find(p => p.id === propertyId);
        if (!property) return [];
        clientId = property.client ? getFirestoreId(property.client) : clientId;
      }
      
      if (!clientId) return [];
      return state.hydrationPlans.filter(plan => plan.client_id === clientId);
    }

  },

    actions: {
    // Fetches properties the user has access to
    async fetchUserProperties() {
      const userStore = useUserStore();
      const filters: any = [];

      // Check if the user is not a superuser
      if (!userStore.isSuperuser) {
        filters.push({
          field: "staff",
          operator: "array-contains",
          value: { table: "accounts", id: userStore.account.id },
        });
      }

      const foundProperties = await fetcher(apiEndpoints.findObjects("properties"), { method: 'POST' }, { filters });

      if (foundProperties) {
        this.properties = [];
        each(foundProperties.data, (p: any) => {
          this.properties.push({
            name: p.name,
            logo: getImageUrl(p.logo),
            client_property_ref: p.client_property_ref,
            id: p.id,
            settings: p.settings || {},
            client: p.client,
          });
        });
      } else {
        throw new Error("Properties not found");
      }
    },

    async fixRulesData() {
      console.log("updating rules data");
      each(this.properties, (property, index) => {
        each(property.access_rules, (rule, pIndex) => {
          // console.log(index, pIndex);
          if(Array.isArray(rule.keys)) this.properties[index].access_rules![pIndex].keys = rule.keys.join(", ");
        })
      })
    },

    // Fetches a property based on the provided property id
    async fetchProperty(propertyId: string) {
      const response = await fetcher(apiEndpoints.getObject("properties", propertyId));

      if (response.error) {
        throw new Error(response.error);
      } else {
        const p = response.data;
        p.id = p.id || propertyId;
        p.logo = getImageUrl(p.logo);
        p.access_rules = p.access_rules || []; // add this line
        const client_id = getFirestoreId(p.client);

        if(p.client && client_id) {
          
          // check for hydration plans
          const hps = await fetcher(apiEndpoints.findObjects("hydration_plans"), { method: 'POST' }, { filters: [{ field: "client", operator: "==", value: { table: 'clients', id: client_id } }] });
          if(hps.error) {
            console.error(hps.error);
          } else {
            // iterate through each hydration plan and add it to the state
            this.hydrationPlans = [];
            each(hps.data, (hp: any) => {
              this.hydrationPlans.push({
                name: hp.name,
                display_name: hp.display_name,
                id: hp.id,
                client_id: client_id,
              });
            });
          }
        }
        // if property has access rules, iterate through each and check if .keys is an array, if so turn it into comma separated string
        // check if property is already in the properties array, if it is then replace it with the new one otherwise push it
        const index = this.properties.findIndex((p) => p.id === propertyId);
        if (index > -1) {
            this.properties[index] = p;

        } else {
            this.properties.push(p);
        }

        // TODO: find a better way
        //this.fixRulesData();
      }
    },
    async addAccessRule(propertyId: string, newRule: AccessRules) {
      if(!propertyId) return console.error("Property ID is required");
      if(!newRule) return console.error("New rule is required");

      console.log(propertyId, newRule);

      const propertyIndex = this.properties.findIndex((p) => p.id === propertyId);
      console.log({propertyIndex});
      if (propertyIndex === -1) {
        throw new Error("Property not found");
      }
  
      // Add new rule to local state
      this.properties[propertyIndex].access_rules!.push(newRule);
  
      // Update property in backend
      await this.updatePropertyAccessRulesInBackend(propertyId, this.properties[propertyIndex]);
    },
  
    async editAccessRule(propertyId: string, ruleIndex: number, updatedRule: AccessRules) {
      const propertyIndex = this.properties.findIndex((p) => p.id === propertyId);
      if (propertyIndex === -1) {
        throw new Error("Property not found");
      }
  
      // Update rule in local state
      this.properties[propertyIndex].access_rules![ruleIndex] = updatedRule;
  
      // Update property in backend
      await this.updatePropertyAccessRulesInBackend(propertyId, this.properties[propertyIndex]);
    },
  
    async removeAccessRule(propertyId: string, ruleIndex: number) {
      const propertyIndex = this.properties.findIndex((p) => p.id === propertyId);
      if (propertyIndex === -1) {
        throw new Error("Property not found");
      }
  
      // Remove rule from local state
      this.properties[propertyIndex].access_rules!.splice(ruleIndex, 1);
  
      // Update property in backend
      await this.updatePropertyAccessRulesInBackend(propertyId, this.properties[propertyIndex]);
    },
  
    async updatePropertyAccessRulesInBackend(propertyId: string, updatedProperty: Property) {
      console.log("updating on backend");
      // Call to API to update the property
      const access_rules = updatedProperty.access_rules
      if(access_rules) {
        each(access_rules, (rule, index) => {
          if(rule.keys && typeof(rule.keys) === "string") {
            const splitKeys = rule.keys.split(",");
            each(splitKeys, (key, keyIndex) => {
              splitKeys[keyIndex] = key.trim();
            })
            access_rules[index].keys = splitKeys;
          }
        })
      }
      const response = await fetcher(apiEndpoints.updateObject("properties", propertyId), 
      { method: 'POST' }, {"access_rules": access_rules});

      //TODO: Find a better way
      this.fixRulesData();
  
      if (response.error) {
        throw new Error(response.error);
      }
    }

  },
});
