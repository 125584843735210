<!-- src/components/FooterComponent.vue -->
<template>
    <footer>
      <p>v{{ version }}</p>
    </footer>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    data() {
      return {
        version: '',
      };
    },
    mounted() {
      this.getVersion();
    },
    methods: {
      async getVersion() {
        try {
          const pkg = await import('../../package.json');
          this.version = pkg.version;
        } catch (error) {
          console.error('Error fetching app version:', error);
        }
      },
    },
  });
  </script>
  
  <style scoped lang="scss">
  /* Add your footer styles here */
  footer {
    position: absolute;
    bottom: 10px;
    right: 0px;
    //height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.2;
    font-size: 0.7rem;
    font-weight: bold;
    transform: rotate(90deg);
  }
  </style>
  