<template>
    <div class="reservations-admin">
        <div class="breadcrumbs">
            <router-link v-if="property" :to="'/admin/property/' + propertyId" class="breadcrumb-item">{{ property.name }}</router-link>
            <div class="breadcrumb-arrow">
                <font-awesome-icon icon="caret-right" />
            </div>
            <router-link v-if="property" :to="'/admin/property/' + property.id + '/reservations'" class="breadcrumb-item">Reservations</router-link>
            <div v-if="reservationId" class="breadcrumb-arrow">
                <font-awesome-icon icon="caret-right" />
            </div>
            <div v-if="reservationId" class="breadcrumb-item">
                {{ reservationId === 'new' ? 'New Reservation' : specificReservation?.reservation_id }}
            </div>
        </div>
        <div class="route-inner">
            <div v-if="!specificReservation && isSuperuser && false" class="filter-menu">
                <div v-for="(filter, key) in filters" :key="key" class="filter-item" :class="{'selected': filters[key]}" @click="toggleFilter(key)">
                    <div class="filter-label">{{ key }}</div>
                    <div v-if="filters[key]" class="filter-count">({{ reservations.length  }})</div>
                </div>
            </div>
            <div v-if="loadingReservation">
              <font-awesome-icon icon="spinner" spin />
            </div>
            <ReservationDetails 
                v-else-if="reservationId" 
                :reservation="specificReservation" 
                :mode="reservationId === 'new' ? 'new' : 'edit'"
                @created="onReservationCreated"
                @cancel="onReservationCancel"
            />
            <div v-else-if="propertyId" class="reservations-list">
                <div v-for="(reservation, index) in reservations" :key="reservation.id + '_' + index" class="reservation-item" @click="gotoReservation(reservation.link)" :class="{'is-disabled': loadingReservation}">
                    <div class="room-number">{{  reservation.room  }}</div>
                    <div v-if="reservation.occupants" class="guest-name">{{  reservation.occupants }}</div>
                    <div v-else class="guest-name">{{  reservation.guest_name }}</div>
                    <div v-if="needsDates" class="check-in reservation-detail">
                        <div class="label">Check-in:</div>
                        <div class="value">{{ reservation.check_in }}</div>
                    </div>
                    <div v-if="needsDates" class="check-in reservation-detail">
                        <div class="label">Check-out:</div>
                        <div class="value">{{ reservation.check_out }}</div>
                    </div>
                    <div v-if="reservation.hydration_plans" class="check-in reservation-detail">
                        <div class="label">Hydration Plans:</div>
                        <div class="value">{{ reservation.hydration_plans }}</div>
                    </div>
                    <div class="goto-reservation">
                        <font-awesome-icon v-if="loadingReservation === reservation.link" icon="spinner" spin />
                        <font-awesome-icon v-else icon="chevron-right" />
                    </div>
                  </div>
                <font-awesome-icon v-if="fetchingReservations" icon="spinner" spin />
                <div class="button-group">
                  <button v-if="propertyId && !reservationId" @click="fetchReservations" class="load-more-button" :class="{'loading': fetchingReservations}">Load More</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { apiEndpoints, fetcher } from "@/helpers/apiEndpoints";
import { useUserStore } from "@/stores/user";
import { usePropertyStore } from "@/stores/property";
import { getImageUrl, todayInSpain, getFirestoreId } from "@/helpers/utils";
import dayjs from "dayjs";
import { orderBy } from "lodash";
import ReservationDetails from "@/components/ReservationDetails.vue";
import type { Reservation, Property } from "@/interfaces";

export default defineComponent({
    components: {
        ReservationDetails
    },
  setup() {
    const route = useRoute();
    const propertyId = computed(() => route.params.property_id);
    const reservations = ref<Reservation[]>([]);
    const errorMessage = ref("");
    const router = useRouter();
    const reservationId = computed(() => route.params.reservation_id);
    const specificReservation = ref<Reservation | null>(null);
    const filters: any = ref({
      duplicates: false
    });
    const currentOffset = ref(0); // for pagination
    const userStore = useUserStore();
    const propertyStore = usePropertyStore();
    const property = computed(() => propertyStore.getProperty(propertyId.value as string));
    const isSuperuser = computed(() => userStore.isSuperuser);
    const fetchingReservations = ref(false);
    const loadingReservation = ref<string | boolean>(false);

    const needsDates = computed(() => {
      return propertyId.value && propertyStore.needsDates(propertyId.value as string);
    });

    const hasHydrationPlans = computed(() => {
      return propertyId.value && propertyStore.hasHydrationPlans(propertyId.value as string);
    });

    const gotoReservation = (link: string | null | undefined) => {
      if (loadingReservation.value || !link) return;
      loadingReservation.value = link;
      router.push(link);
    };

    const fetchSpecificReservation = async () => {
      if (!reservationId.value) return;

      //reset specificReservation
      specificReservation.value = null;      

      try {
        const response = await fetcher(apiEndpoints.getObject("reservations", reservationId.value as string));
        if (response && response.data) {
          const r = response.data;
          const checkInDate = r.check_in ? new Date(r.check_in._seconds * 1000 + r.check_in._nanoseconds / 1000000) : null;
          const checkOutDate = r.check_out ? new Date(r.check_out._seconds * 1000 + r.check_out._nanoseconds / 1000000) : null;

          const checkIn = checkInDate ? dayjs(checkInDate).format('MMMM D, YYYY') : "N/A";
          const checkOut = checkOutDate ? dayjs(checkOutDate).format('MMMM D, YYYY') : "N/A";

          // Handle new reservation case
          if (reservationId.value === 'new') {
              specificReservation.value = {
                  id: '',
                  check_in: new Date(),
                  check_out: new Date(),
                  room: '',
                  first_name: '',
                  last_name: '',
                  guest_name: '',
                  pin: '',
                  occupants: [{
                      guest_name: '',
                      email: '',
                      hydration_plan_id: null,
                      first_name: '',
                      last_name: '',
                      member: null,
                      pin: ''
                  }],
                  reservation_id: '',
                  booking_name: ''
              };
              return;
          }


          specificReservation.value = {
            id: r.id,
            check_in: checkIn,
            check_out: checkOut,
            room: r.room && r.room !== "NULL" ? r.room : "N/A",
            first_name: r.first_name,
            last_name: r.last_name,
            guest_name: r.first_name + " " + r.last_name,
            link: `/admin/property/${propertyId.value}/reservations/${r.id}`,
            reservation_id: r.reservation_id,
            unlimited: r.unlimited,
            credit: r.credit,
            refills: r.refills,
            pin: r.pin,
            occupants: r.occupants,
            booking_name: r.first_name + " " + r.last_name
          };

          // iterate through occupants and get hydration plan names and ids
          if(r.occupants) {
            specificReservation.value.occupants = r.occupants.map((o: any) => {
              let hp_id = getFirestoreId(o.hydration_plan) || null;
              let hp_details = hp_id ? propertyStore.getHydrationPlan(hp_id) : null;
              return {
                first_name: o.first_name,
                last_name: o.last_name,
                email: o.email,
                hydration_plan_name: hp_details ? hp_details.display_name : "N/A",
                hydration_plan_id: hp_id,
                hydration_plan: o.hydration_plan,
                guest_name: o.first_name + " " + o.last_name
              };
            });
          }

          
        } else {
          errorMessage.value = "Reservation not found";
        }

        loadingReservation.value = false;

      } catch (error) {
        errorMessage.value = "Error fetching reservation";
        loadingReservation.value = false;
        console.error(error);
      }
    };

    if (!propertyId.value) {
      router.push("/admin/property");
    } else {
        propertyStore.fetchProperty(propertyId.value as string);
    }

    const fetchReservations = async () => {

      fetchingReservations.value = true;

      try {

        let filterList: any = [
          {
            field: "property_ref",
            operator: "==",
            value: { table: "properties", id: propertyId.value },
          }
        ]

        let orderBy = { field: "updated_at", direction: "asc" };

        if(needsDates.value) {
          filterList.push({
            field: "check_out",
            operator: ">=",
            value: todayInSpain,
          });

          orderBy = { field: "check_out", direction: "asc" };
        }

        

        const queryParams = {
          filters: filterList,
          orderBy: orderBy,
          limit: 20,
          offset: currentOffset.value
        }

        console.log(queryParams);

        if(filters.value['duplcates']) {
          reservations.value = [];
        }

        const response = await fetcher(apiEndpoints.findObjects("reservations"), {
            method: "POST",
          },
          {
            ...queryParams
          }
        );

        if (response && response.data) {

            reservations.value = reservations.value.concat(response.data.map((r: any) => {
                const checkInDate = r.check_in ? new Date(r.check_in._seconds * 1000 + r.check_in._nanoseconds / 1000000) : null;
                const checkOutDate = r.check_out ? new Date(r.check_out._seconds * 1000 + r.check_out._nanoseconds / 1000000) : null;

                const checkIn = checkInDate ? dayjs(checkInDate).format('MMMM D, YYYY') : "N/A";
                const checkOut =checkOutDate ? dayjs(checkOutDate).format('MMMM D, YYYY') : "N/A";

                let occupants = r.occupants ? r.occupants : null;

                let occupant_names = null;
                if(occupants) {
                    occupant_names = occupants.map((o: any) => {
                        return o.first_name + " " + o.last_name;
                    }).join(", ");
                }

                let hydration_plans = r.occupants ? r.occupants.map((o: any) => {
                    return o.hydration_plan;
                }) : null;

                let hydration_plan_names = null;

                // we can get a strign of names from the hydration plans in property store
                if(hydration_plans) {
                    hydration_plan_names = hydration_plans.map((hp: any) => {
                        let hp_id = getFirestoreId(hp);
                        if(!hp_id) return "N/A";
                        let hp_details = propertyStore.getHydrationPlan(hp_id);
                        return hp_details ? hp_details.display_name : "N/A";
                    }).join(", ");
                }
                


                return {
                    id: r.id,
                    check_in: checkIn,
                    check_out: checkOut,
                    reservation_id: r.reservation_id,
                    room: r.room && r.room !== "NULL" ? r.room : "N/A",
                    first_name: r.first_name,
                    last_name: r.last_name,
                    guest_name: r.first_name + " " + r.last_name,
                    link: `/admin/property/${propertyId.value}/reservations/${r.id}`,
                    pin: r.pin,
                    occupants: occupant_names,
                    hydration_plans: hydration_plan_names
                };
            }));


            if(queryParams.limit) currentOffset.value += queryParams.limit;

            
            
        } else {
          errorMessage.value = "No reservations found";
        }
      } catch (error) {
        errorMessage.value = "Error fetching reservations";
        console.error("error in reservations");
        console.error(error);
      }

      fetchingReservations.value = false;
    };

    const toggleFilter = async (key: any) => {
      filters.value[key] = !filters.value[key];
      fetchReservations();
    };

    const start: () => void = async () => {
        if (reservationId.value) {
            loadingReservation.value = true;
            console.log("getting specific reservation", reservationId.value)
            await fetchSpecificReservation();
            loadingReservation.value = false;
        } else {
            specificReservation.value = null;
            console.log("getting all reservations")
            await fetchReservations();
        }
    };

    const onReservationCreated = (newReservation: Reservation) => {
        router.push(`/admin/property/${propertyId.value}/reservations/${newReservation.id}`);
        // Optionally refresh the reservations list
        fetchReservations();
    };

    const onReservationCancel = () => {
        router.push(`/admin/property/${propertyId.value}/reservations`);
    };

    // Watch for route changes
    watch(
        () => route.params,
        () => {
            start();
        },
        { deep: true }
    );

    start();

    // onMounted(async () => {
    //     start();
    // });

    return {
        filters,
        toggleFilter,
        isSuperuser,
        property,
        propertyId,
        reservations,
        errorMessage,
        fetchReservations,
        reservationId,
        specificReservation,
        fetchSpecificReservation,
        fetchingReservations,
        needsDates,
        hasHydrationPlans,
        loadingReservation,
        gotoReservation,
        currentOffset,
        onReservationCreated,
        onReservationCancel

    };
  },
});
</script>

<style scoped lang="scss">
  .reservations-admin {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .breadcrumbs {
        width: 100%;
        padding: 0px 1rem;
    }
  }
  
  .reservations-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .reservations-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding: 1rem;

    // @media (min-width: 768px) {
    //   flex-direction: row;
    //   flex-wrap: wrap;
    //   justify-content: center;
    // }
  }
  
  .reservation-item {
    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.3s ease;
    width: 100%;
    font-size: 0.9rem;

    &:link, &:visited {
        color: $colorOneBlue;
        text-decoration: none;
    }

    &:hover {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        background-color: #f5f5f5;
    }


        .room-number {
            font-weight: bold;
            display: flex;
            font-size: 0.8rem;
            flex: 0.2;
            align-items: left;
            justify-content: center;
            padding: 0px 7px;
            padding-left: 0px;
        }

        .guest-name {
            font-weight: bold;
            display: flex;
            flex: 2;
            font-size: 0.8rem;
        }

        &:last-child {
          margin-bottom: 100px;
        }

        .reservation-detail {
            display: flex;
            flex-direction: column;
            padding: 0px 10px;
            flex: 1;
            height: 100%;

            
            .label {
                font-weight: bold;
                font-size: 0.6rem;
                display: flex;
            }

            .value {
                font-size: 0.7rem;
                display: flex;
                flex-wrap: nowrap;
                white-space: nowrap;
                flex: 1;
            }
        }

        .goto-reservation {
            display: flex;
            flex: 0.1;
            align-items: center;
            justify-content: center;
            padding-right: 0px;
        }

        @media screen and (min-width: 768px) {
            

            .logo {
              height: auto;
              
            }

            .name {
              font-size: 0.8rem;
              margin-top: 0px;
            }

            &:last-child {
              margin-bottom: 20px;
            }
        }

        &.is-disabled {
            opacity: 0.5;
            pointer-events: none;
        }

  }
  
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .find-reservation {
    gap: 1rem;
  }
  
  .error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0
}

.filter-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;

  .filter-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 0.5rem;
    background-color: rgba($colorOneBlue, 0.1);
    color: $colorOneBlue;
    border: solid 2px transparent;
    border-radius: 5px;
    font-size: 0.7rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: rgba($colorOneBlue, 0.2);
    }

    &.selected {
      background-color: $colorOneBlue;
      color: $colorOneGreen;
      border: solid 2px $colorOneBlue;
    }

  }

}

.load-more-button {
  margin-top: 1rem;
}

button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #0056b3;
  }
}
</style>  
  