import { defineStore } from 'pinia';
import type { User } from 'firebase/auth';
import { auth, db } from '@/helpers/firebaseSetup';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { apiEndpoints, fetcher } from '@/helpers/apiEndpoints';
import { each } from 'lodash';
import type { UserState } from '@/interfaces';
import { nanoid } from 'nanoid';

export const useUserStore = defineStore({
  id: 'user',

  state: (): UserState => ({
    user: null,
    customClaims: null,
    account: null,
    userProfile: null,
    authChecked: false,
    roles: null
  }),
  getters: {
    initials: (state): string => {
        if(!state.user) return "";
        if(state.user.displayName) return state.user.displayName.match(/\b(\w)/g).join('').slice(0, 3);
        if(state.user.email) return state.user.email.match(/\b(\w)/g).join('').slice(0, 3);
        return "";
    },
    isAuthorised: (state): any => {
        let authorised = state.user !== null && state.customClaims !== null; 
        if(!authorised) return false;

        const roles: any = {
            admin: state.customClaims.admin,
            superuser: state.customClaims.superuser,
            ...state.customClaims.roles,
            ...(state.account ? state.account.roles : null)
        }

        console.log("roles", roles);

        authorised = roles.admin || roles.superuser || roles.client_admin || roles.manager;
        
        if(!authorised) return false;

        return { roles }
    },
    isSuperuser: (state): boolean => {
        return state.customClaims && state.customClaims.superuser;
    },
    isAdmin: (state): boolean => {
        return state.customClaims && state.customClaims.admin;
    }
  },
  actions: {
    setUser: async function (user: User | null) {
        console.log("setting user...");
        
        if(user === null) {
          this.user = null;
          this.account = null;
          this.userProfile = null;
          this.customClaims = null;
          this.roles = null;
          return console.log("user unset");
        }

        if(this.user && user && this.user.uid === user.uid) return;

        this.user = {
          uid: user.uid,
          email: user.email,
          emailVerified: user.emailVerified,
          displayName: user.displayName,
          photoURL: user.photoURL
        };

        this.roles = {};

        await auth.currentUser?.getIdTokenResult()
        .then(async (idTokenResult) => {
            this.customClaims = idTokenResult.claims;
            each(this.customClaims.roles, (role: any, key: string) => {
              if(role) this.roles[key] = true;
            });

            const accountResponse: any = await fetcher(apiEndpoints.findObjects("accounts"), {
              method: "POST",
            },
            {
              filters: [
                {
                  field: "auth_id",
                  operator: "==",
                  value: this.user?.uid
                }
              ],
              limit: 1
            })

            if(accountResponse && accountResponse.data && accountResponse.data.length > 0) {
              if(!this.user) return;

              this.account = accountResponse.data[0];

              each(this.account.roles, (role: any, key: string) => {
                if(role) this.roles[key] = true;
              });

              const userProfileResponse: any = await fetcher(apiEndpoints.findObjects("users"), {
                method: "POST",
              },
              {
                filters: [
                  {
                    field: "auth_id",
                    operator: "==",
                    value: user.uid
                  }
                ],
                limit: 1
              })
              if(userProfileResponse && userProfileResponse.data && userProfileResponse.data.length > 0) {
                this.userProfile = userProfileResponse.data[0];
              }
              return console.log("user set");
            }
        })
        .catch((error) => {
            console.log(error);
        })
    },
    handleUser: async function(user: User | null) {
      await this.setUser(user);
      return this.isAuthorised;
    },
    setAuthChecked(checked: boolean) {
      this.authChecked = checked;
    },
    async logout() {
      await auth.signOut();
      this.setUser(null);
    },
    getOrCreateMember: async function(email: string, name: string, unlimited?: any[]) {
      try {
        // First check if user exists using the API endpoint
        const userResponse = await fetcher(
          apiEndpoints.findUserByEmail(), 
          { method: 'GET' },
          { email }
        );

        let uid;
        
        if (userResponse.success && userResponse.data) {
          // User exists in Firebase Auth
          uid = userResponse.data.uid;
          console.log("User exists in Firebase Auth", uid);
          
          // Check if member exists in Firestore
          const memberQuery = query(
            collection(db, 'members'), 
            where('uid', '==', uid)
          );
          const memberDocs = await getDocs(memberQuery);
          
          if (!memberDocs.empty) {
            // Member exists, return it
            console.log("Member exists in Firestore", memberDocs.docs[0].id);
            const m = memberDocs.docs[0].data();
            return m;
          }
        } else {
          // User doesn't exist, create new Firebase auth user via API
          console.log("User doesn't exist in Firebase Auth, creating new user");
          const tempPassword = nanoid();
          const createUserResponse = await fetcher(
            apiEndpoints.createUserWithEmailAndPassword(),
            { method: 'POST' },
            { email, password: tempPassword }
          );
          
          if (!createUserResponse.success) {
            throw new Error("Failed to create user");
          }
          
          uid = createUserResponse.data.uid;
          console.log("New user created in Firebase Auth", uid);
        }

        // Create new member document
        const id = doc(collection(db, 'members')).id;
        const ak_member_id = `ak-${id}`;
        const [firstName, ...lastNameParts] = name.split(' ');
        const lastName = lastNameParts.join(' ');

        const newMember: any = {
          ak_member_id,
          created_at: new Date().toISOString(),
          displayName: name,
          email: email,
          id: id,
          name: {
            first: firstName,
            last: lastName || ''
          },
          notifTokens: [],
          qr_code_image: "",
          qr_url: `https://qr.aguakmzero.com/make?text=${ak_member_id}`,
          stats: {
            bottles: 0,
            duration: 0,
            refills: 0
          },
          uid: uid,
          unlimited: unlimited ? unlimited : []
        };

        console.log("Creating new member in Firestore", newMember);

        // Create member document in Firestore
        const memberRef = doc(db, 'members', id);
        await setDoc(memberRef, newMember);
        return newMember;
      } catch (error) {
        console.error("Error in getOrCreateMember:", error);
        throw error;
      }
    }
  },
});