<template>
    <div v-if="isAuthorised" class="menu-bar">
        <Logo shade="light" />
        <nav class="nav">
            <router-link class="menu-item" to="/admin/property/">
                <font-awesome-icon icon="building" />
                <div class="caption">Properties</div>
            </router-link>
            <router-link v-if="showPropertyDetails && hasReservations" class="menu-item" :to="reservationsLink || '/'">
                <font-awesome-icon icon="bed" />
                <div class="caption">Reservations</div>
            </router-link>
            <div v-else-if="hasReservations" class="menu-item is-disabled">
                <font-awesome-icon icon="bed" />
                <div class="caption">Reservations</div>
            </div>
            <!-- TODO:  
                Figure out how we're going to implement separate access keys for each property
            -->
            <!-- <router-link v-if="showPropertyDetails && hasAccessKeys" class="menu-item" :to="reservationsLink || '/'">
                <font-awesome-icon icon="id-card" />
                <div class="caption">Access Keys</div>
            </router-link>
            <div v-else-if="hasAccessKeys" class="menu-item is-disabled">
                <font-awesome-icon icon="id-card" />
                <div class="caption">Access Keys</div>
            </div> -->
            <router-link v-if="usersEnabled" class="menu-item" :to="usersLink || '/'">
                <font-awesome-icon icon="users" />
                <div class="caption">Users</div>
            </router-link>
            <div v-else-if="showUsers" class="menu-item is-disabled">
                <font-awesome-icon icon="users" />
                <div class="caption">Users</div>
            </div>
            <div class="menu-item user-icon">
                {{ initials }}
            </div>
            <div class="menu-item" @click="logout">
                <font-awesome-icon icon="sign-out" />
                <div class="caption">Logout</div>
            </div>
        </nav>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useUserStore } from '@/stores/user';
  import { usePropertyStore } from '@/stores/property';
  import Logo from "@/components/LogoComponent.vue"
  
  export default defineComponent({
    name: 'MenuBar',
    components: {
      Logo
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      const userStore = useUserStore();
      const propertyStore = usePropertyStore();
      const propertyId = computed(() => {
        const { property_id } = route.params;
        return Array.isArray(property_id) ? property_id[0] : property_id;
    });

      let property = computed(() => propertyStore.property);
      let propertySettings = computed(() => propertyStore.getSettings(propertyId.value) );
      
      const hasReservations = computed(() => propertySettings.value.reservations);
      const hasAccessKeys = computed(() => propertySettings.value.access_keys);
      
      
      const isAuthorised = computed(() => userStore.isAuthorised);
      const initials = computed(() => userStore.initials);

      const showPropertyDetails = computed(() => { return route.path.includes('/admin/property/') && route.params.property_id});
      const showUsers = computed(() => { return userStore.isSuperuser; });
      const usersEnabled = computed(() => { return showUsers.value && route.path.includes('/admin/property/') && route.params.property_id });

      const reservationsLink = computed(() => { return route.params.property_id ? '/admin/property/' + route.params.property_id + '/reservations' : null });
      const usersLink = computed(() => { return route.params.property_id ? '/admin/property/' + route.params.property_id + '/users' : null });
      
      
  
      const logout = async function() {
        await userStore.logout();
        router.push({ name: 'login' });
      }
  
      return {
        isAuthorised,
        showPropertyDetails,
        hasReservations,
        hasAccessKeys,
        showUsers,
        propertyId,
        reservationsLink,
        usersLink,
        usersEnabled,
        initials,
        logout,
        property
      };
    },
  });
  </script>
  
  <style scoped lang="scss">
  .menu-bar {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: -1px;
    left: 0;
    width: 100%;
    background-color: $colorOneBlue;
    color: $colorOneGreen;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
    padding: 10px 15px;

    .nav {
        display: flex;
        justify-content: space-around;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }
  }
  
  .menu-item {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1rem;
    padding: 10px;
    flex: 1;
    

    svg {
        font-size: 1.4rem;
        margin-bottom: 5px;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    @media (min-width: 768px) {
        margin-bottom: 10px;
    }

    &:visited, &:link, &:active {
        color: $colorOneGreen;
        text-decoration: none;
    }

    .caption {
        font-size: 1rem;
    }

    &.is-disabled {
        &:hover {
            background-color: transparent;
        }

        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
    }

    .user-icon {
        display: none;
    }

  }

  .menu-bar {
    @media (min-width: 768px) {
        flex-direction: column;
        position: unset;
        top: 0;
        left: 0;
        bottom: auto;
        height: 100%;
        justify-content: flex-end;

        .nav {
            flex-direction: column;
            width: auto;
            margin: 0;
            display: flex;
            flex: 1;
            margin-top: 20px;
            max-width: 100%;

        }

        .menu-item {
            font-size: 1rem;
            padding: 10px;
            //padding-bottom: 20px;
            flex: unset;

            svg {
                font-size: 1rem;
            }

            .caption {
                font-size: 0.5rem;
            }

            &:last-child {
                //margin-top: auto;
                //padding-bottom: 10px;
                margin-bottom: 0px;
            }

        }

    }

    .user-icon {
        margin: auto;
        margin-bottom: unset;
        margin-top: auto;
        width: 40px;
        height: 40px;
        border: solid 2px $colorOneGreen;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 0.8rem;
        padding: 5px;
        margin-bottom: 20px;
        font-weight: bold;

        &:hover {
            background-color: transparent;
        }
    }
  }
  </style>
  