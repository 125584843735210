import type { Reservation } from "@/interfaces";

interface ValidationResult {
    isValid: boolean;
    errors: string[];
}

interface ValidationOptions {
    mode: 'edit' | 'new';
    pinMaxLength: number;
}

export const validateReservation = (
    reservation: Reservation | null,
    originalReservation: Reservation | null,
    options: ValidationOptions
): ValidationResult => {
    const errors: string[] = [];

    if (!reservation) {
        errors.push("Reservation data is required");
        return {
            isValid: false,
            errors
        };
    }

    // Basic required fields validation
    if (!reservation.room) {
        errors.push("Room is required");
    }

    if (!reservation.pin) {
        errors.push("PIN is required");
    } else {
        // PIN format validation
        if (!/^\d+$/.test(reservation.pin)) {
            errors.push("PIN must contain only numbers");
        }
        if (reservation.pin.length !== options.pinMaxLength) {
            errors.push(`PIN must be ${options.pinMaxLength} digits`);
        }
    }

    // Occupants validation
    if (!reservation.occupants?.length) {
        errors.push("At least one guest is required");
    } else {
        reservation.occupants.forEach((occupant, index) => {
            if (!occupant.guest_name) {
                errors.push(`Guest ${index + 1} name is required`);
            }
            if (!occupant.email) {
                errors.push(`Guest ${index + 1} email is required`);
            }
            if (!occupant.hydration_plan_id) {
                errors.push(`Guest ${index + 1} hydration plan is required`);
            }
        });
    }

    // For edit mode, check if there are actual changes
    if (options.mode === 'edit' && originalReservation) {
        const hasChanges = detectChanges(reservation, originalReservation);
        if (!hasChanges) {
            errors.push("No changes detected");
        }
    }

    return {
        isValid: errors.length === 0,
        errors
    };
};

const detectChanges = (current: Reservation, original: Reservation): boolean => {
    
    const fieldsToCheck = ['room', 'guest_name', 'check_in', 'check_out', 'pin'] as const;
    type FieldsToCheck = typeof fieldsToCheck[number];

    const mainFieldsChanged = fieldsToCheck.some(
        (field: FieldsToCheck) => original[field] !== current[field]
    );

    // Compare occupants
    const originalOccupants = original.occupants || [];
    const currentOccupants = current.occupants || [];

    // Check for length changes
    if (originalOccupants.length !== currentOccupants.length) {
        return true;
    }

    // Check for content changes
    const occupantsChanged = originalOccupants.some((originalOccupant, index) => {
        const currentOccupant = currentOccupants[index];
        if (!currentOccupant) return true;
        return JSON.stringify(originalOccupant) !== JSON.stringify(currentOccupant);
    });

    return mainFieldsChanged || occupantsChanged;
};

// Helper for generating error messages
export const getErrorMessage = (error: string): string => {
    const errorMessages: Record<string, string> = {
        "update-failed": "Sorry we couldn't save changes. Try again later.",
        "name-required": "Name is required for all guests.",
        "email-required": "Email is required for all guests.",
        "pin-required": "Base PIN is required.",
        "pin-invalid-format": "PIN must contain only numbers.",
        "hydration-plan-required": "Hydration Plan is required for all guests.",
        "no-changes": "No changes to save.",
    };

    return errorMessages[error] || error;
};