import { createRouter, createWebHistory } from 'vue-router';
// import { getAuth, onAuthStateChanged,  } from 'firebase/auth'; // Import Firebase authentication methods
import Property from '../views/PropertyView.vue';
import RefillPass from '../views/RefillPass.vue';
import PropertyAdmin from '../views/admin/PropertyAdmin.vue';
import ReservationsAdmin from '../views/admin/ReservationsAdmin.vue';
import UsersAdmin from '@/views/UsersAdmin.vue';
import HomeView from "@/views/HomeView.vue";
import SearchView from '@/views/SearchView.vue';
import Login from '../views/LoginView.vue'; // Import the Login view
import { auth } from "../helpers/firebaseSetup";
import { useUserStore } from '@/stores/user';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      //alias: "/home",
      name: "home",
      component: HomeView,
    },
    {
      path: '/search',
      name: 'search',
      component: SearchView,
    },
    {
      path: '/property/:property_id?',
      name: 'property',
      component: Property,
      props: true
    },
    {
      path: '/pass/:type?/:pass_id?',
      name: 'pass',
      component: RefillPass,
      props: true,
    },
    {
      path: '/admin',
      name: 'admin',
      meta: { requiresAuth: true }, // Indicates that all child routes require authentication
      redirect: { name: 'admin-property' },
      children: [
        {
          path: 'property/:property_id?',
          name: 'admin-property',
          component: PropertyAdmin,
          props: true
        },
        {
          path: 'property/:property_id/reservations/:reservation_id?',
          name: 'admin-reservations',
          component: ReservationsAdmin,
          props: true
        },
        {
          path: 'property/:property_id/users/:user_id?',
          name: 'admin-users',
          component: UsersAdmin,
          props: true
        }
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    }
  ],
});


// Global navigation guard to check for authentication
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const userStore = useUserStore();

  // If the route doesn't require authentication, proceed with navigation
  if (!requiresAuth) {
    next();
    return;
  }

  const user = auth.currentUser;
  const isAuthenticated = !!user;

  if (isAuthenticated) {
    // Call handleUser and check if the user is authorized
    await userStore.handleUser(user);
    // set query.redirect to the full path including params
    userStore.isAuthorised ? next() : next({ name: 'login', query: { redirect: to.fullPath } });
  } else {
    next({ name: 'login', query: { redirect: to.fullPath } });
  }
});



export default router;
