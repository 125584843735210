<template>
  <div class="logo" :class="['type-' + logoStyle]">
    <img :src="logoImage" alt="Logo" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import logoDefault from '@/assets/logos/logo.svg';
import logoLight from '@/assets/logos/verde.png';

export default defineComponent({
  name: 'LogoComponent',
  props: {
    shade: {
      type: String,
      default: 'default',
    },
  },
  setup(props) {
    const logoStyle = computed(() => props.shade);
    console.log(logoStyle.value);
    const logoImage = computed(() => {
      switch (logoStyle.value) {
        case 'light':
          return logoLight;
        default:
          return logoDefault;
      }
    });

    return {
      logoImage,
      logoStyle,
    };
  },
});
</script>

  
  <style scoped lang="scss">
  .logo {
    /* Logo container styles */
    //height: 10vh;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  img {
    /* Logo image styles */
    height: 100%;
  }
  </style>
  