<template>
    <div class="login-view">
      <Notification
        v-for="(notification, index) in notifications"
        :key="index"
        :message="notification.message"
        :type="notification.type"
        :duration="notification.duration"
      />
      <div v-if="authChecked" class="login-elements">
        <h1>Login</h1>
        <form @submit.prevent="loginWithEmail" class="login-form">
          <div class="input-row">
            <label for="email">Email:</label>
            <input type="email" id="email" v-model="email" required />
          </div>
          <div class="input-row">
            <label for="password">Password:</label>
            <input type="password" id="password" v-model="password" required />
          </div>
          <div class="button-row">
            <button type="submit" class="button secondary-button smaller fullwidth">Login with Email</button>
          </div>
        </form>
        <p class="sep">Or</p>
        <button @click="loginWithGoogle" class="button primary-button">Login with Google</button>
      </div>
      <div v-if="!authChecked" class="thinking-overlay">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import { auth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "@/helpers/firebaseSetup";
  import { useUserStore } from "@/stores/user";
  import { onAuthStateChanged } from "firebase/auth";
  import Notification from "@/components/NotificationBubble.vue";
  import { useNotificationStore } from "@/stores/notificationStore";
  
  export default defineComponent({
    name: "LoginView",
    components: {
      Notification,
    },
    setup() {
      const router = useRouter();
      const email = ref("");
      const password = ref("");
      var userStore = useUserStore();
      const route = useRoute();
      const notifications: any = ref([]);
      const { showNotification } = useNotificationStore();
      const checkingAuth = ref(true);
      

      // an errorMessages object that maps firebase/auth error codes to human readable messages
      const errorMessages: any = {
        "auth/invalid-email": "Invalid email address",
        "auth/user-disabled": "User account has been disabled",
        "auth/user-not-found": "User not found",
        "auth/wrong-password": "Incorrect password",
        "auth/email-already-in-use": "Email already in use",
        "auth/operation-not-allowed": "Operation not allowed",
        "auth/weak-password": "Password is too weak",
      };

      const getErrorMessage = (errorCode: string) => {
        return errorMessages[errorCode] || "An error occurred";
      };
      

      const user: any = computed(() => userStore.user);
      const isAuthorised = computed(() => userStore.isAuthorised);
      const authChecked = computed(() => userStore.authChecked);
  
      async function loginWithEmail() {
        try {
          const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value);
          console.log("logged in with email");
        } catch (error: any) {
          console.error("error on loginWithEmail");
          console.log({error});
          showNotification(getErrorMessage(error.code), "error");
        }
      }
  
      async function loginWithGoogle() {
        try {
          const provider = new GoogleAuthProvider();
          provider.setCustomParameters({
            client_id: '38562129589-p6glgtral0na3g2c70i8o3484m6br0vn.apps.googleusercontent.com',
          });
          const userCredential = await signInWithPopup(auth, provider);
          console.log("logged in with google");
        } catch (error: any) {
          console.error("error on loginWithGoogle");
          console.log({error});
          showNotification(getErrorMessage(error.code), "error");
        }
      }

      onAuthStateChanged(auth, async (authedUser) => {
        
        // set checkingAuth to false
        checkingAuth.value = false;
        
        console.log("auth state changed", authedUser);
        if (authedUser) {
          await userStore.handleUser(authedUser);
          userStore.setAuthChecked(true);
          console.log("isAuthorised", isAuthorised.value);
          if(isAuthorised.value) {
            
            var next = route.query.redirect || "/admin";
            router.push(next as string);
          } else {
            
            showNotification("Unauthorised user", "error");
            userStore.logout();
          }
        } else {
          userStore.setAuthChecked(true);
          userStore.setUser(null);
        }
      });
      
  
      return {
        notifications,
        authChecked,
        email,
        password,
        loginWithEmail,
        loginWithGoogle,
      };
    },
  });
  </script>
  
  <style scoped lang="scss">
  .login-view {
    width: 80vw;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;

    .login-elements {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    .input-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;
      width: 100%;
      align-items: center;

      label {
        font-weight: bold;
        color: $colorOneBlue;
        font-size: 1.1rem;
      }

      input {
        width: 80%;
        margin-top: 5px;
      }
    }

  }

  .sep {
    margin: 1rem 0;
    font-weight: 2rem;
    margin-top: 2rem;
    text-transform: uppercase;
    font-weight: bold;
  }


  .thinking-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  </style>
  