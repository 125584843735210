import { defineStore } from 'pinia';

interface NotificationState {
  message: string;
  type: 'success' | 'error' | 'warning';
  show: boolean;
  timeout: number | null;
}

export const useNotificationStore = defineStore({
  id: 'notification',
  
  state: (): NotificationState => ({
    message: '',
    type: 'success',
    show: false,
    timeout: null
  }),

  actions: {
    showNotification(message: string, type: 'success' | 'error' | 'warning' = 'success', duration = 3000) {
      // Clear any existing timeout
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.message = message;
      this.type = type;
      this.show = true;

      // Auto hide after duration
      this.timeout = window.setTimeout(() => {
        this.hideNotification();
      }, duration);
    },

    hideNotification() {
      this.show = false;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    }
  }
});
