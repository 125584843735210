<template>
  <div class="app-inner" :class="[name, {'admin-pages': adminPages}]">
    <HeaderComponent v-if="!adminPages" />
    <div class="router-container" :class="{'admin-pages': adminPages}">
      <router-view />
    </div>
    <NotificationBubble />
    <MenuBar v-if="adminPages"/>
    <FooterComponent />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import HeaderComponent from '@/components/HeaderComponent.vue';
import MenuBar from '@/components/MenuBar.vue';
import NotificationBubble from '@/components/NotificationBubble.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default defineComponent({
  name: 'App',
  components: { MenuBar, NotificationBubble, FooterComponent, HeaderComponent },
  setup() {
    const route = useRoute();

    // Use computed for name to make it reactive
    const name = computed(() => {
      switch(route.path) {
        case "/":
          return "home";
        default:
          return "";
      }
    });

    // Use route.path in the computed property
    const adminPages = computed(() => {
      return route.path.startsWith('/admin');
    });

    return { 
      adminPages, 
      name
    };
  }
});
</script>

<style lang="scss">
@use '@/styles/index' as *;

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  overflow: hidden;
  position: relative;
  
}


@media screen and (max-width: 600px) {
  .logo {
    height: 5vh;
  }
}

@media screen and (min-width: 600px) {
  .logo {
    height: 7vh;
  }
}
</style>
