<template>
    <div class="add-user">
      <h2>Add User</h2>
      <form @submit.prevent="submitForm">
        <div class="input-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div class="input-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <div class="input-group">
          <label for="first_name">First Name:</label>
          <input type="text" id="first_name" v-model="first_name" required />
        </div>
        <div class="input-group">
          <label for="last_name">Last Name:</label>
          <input type="text" id="last_name" v-model="last_name" required />
        </div>
        <div class="input-group">
          <label>Roles:</label>
          <div>
            <label for="client_admin">Client Admin</label>
            <input type="checkbox" id="client_admin" value="client_admin" v-model="roles.client_admin" />
          </div>
          <div>
            <label for="manager">Manager</label>
            <input type="checkbox" id="manager" value="manager" v-model="roles.manager" />
          </div>
          <div>
            <label for="staff">Staff</label>
            <input type="checkbox" id="staff" value="staff" v-model="roles.staff" />
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, reactive } from 'vue';
  import { fetcher, apiEndpoints } from '@/helpers/apiEndpoints';
  import { useNotificationStore } from '@/stores/notificationStore';
  
  export default defineComponent({
    name: 'AddUser',
    setup() {
      const email = ref('');
      const password = ref('');
      const first_name = ref('');
      const last_name = ref('');
      const roles: any = reactive({
        client_admin: false,
        manager: false,
        staff: false,
    });
    const { showNotification } = useNotificationStore();

      const errorMessage = ref('');
  
      const submitForm = async () => {
        try {
          const payload = {
            email: email.value,
            password: password.value,
            first_name: first_name.value,
            last_name: last_name.value,
            roles: Object.keys(roles).reduce((acc: any, role) => {
            if (roles[role]) {
                acc[role] = true;
            }
            return acc;
            }, {}),
            client_id: '', // Replace with your client_id
            property_id: '', // Replace with your property_id
          };
  
          const response = await fetcher(apiEndpoints.createUser(), { method: 'POST' }, payload);
  
          if (response.success) {
            errorMessage.value = '';
            // Redirect or show a success message
            showNotification('User created successfully', 'success');
          } else {
            errorMessage.value = response.data.message;
            showNotification(response.data.message, 'error');
          }
        } catch (error: any) {
          errorMessage.value = error.message;
          console.error(error);
          showNotification(error.message, 'error');
        }
      };
  
      return {
        email,
        password,
        first_name,
        last_name,
        roles,
        errorMessage,
        submitForm,
      };
    },
  });
  </script>
  
  <style scoped>
  .add-user {
    /* Add your styles here */
  }
  
  .input-group {
    /* Add your styles here */
  }
  
  .error {
    color: red;
  }
  </style>
  