
// src/firebaseConfig.ts

import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, Timestamp} from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

// Replace with your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDA1LplvDsOmMR56CFM57dyTiVC681fQK4",
    authDomain: "h2os-global.firebaseapp.com",
    projectId: "h2os-global",
    storageBucket: "h2os-global.appspot.com",
    messagingSenderId: "38562129589",
    appId: "1:38562129589:web:adc2fe509ec6b3dab340fc",
    measurementId: "G-KKCTX9E7HL"
};


export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Firestore instance
export const db = getFirestore(app);

// Firebase auth instance
export const auth = getAuth(app);

export { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, Timestamp }