import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const todayInSpain = dayjs().tz('Europe/Madrid').startOf('day').utc();

export function fsTimestampToDate(timestamp: any) {
    if(!timestamp || !timestamp._seconds || timestamp._nanoseconds === undefined ) return null;
    return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
}


export function isLocalhost(): boolean {
    const { hostname } = window.location;
  
    return hostname === 'localhost' || hostname === '127.0.0.1' || hostname === '[::1]';
}

const baseUrl = isLocalhost() ? 'http://localhost:8829/api/v1' : 'https://refill.aguakmzero.com/api/v1';


export function getImageUrl(path : string, proxy = false) {
    if(!path) return '';
    let url = `${baseUrl}/storage/getDownloadUrl?file_path=${path}`;
    if(proxy) url = `${baseUrl}/proxy?url=${url}`;
    return url;
}


export function getProxyUrl(path: string) {
    if(!path) return '';
    return `${baseUrl}/proxy?url=${path}`;
}

export function getFirestoreId(docRef: any) {
    // if this has _.path, it's a DocumentReference, extract the path and then the id
    if(!docRef) return null;

    if(docRef && docRef._path) {
        return docRef._path.segments[docRef._path.segments.length - 1];
    }
}

export function copyToClipboard(text: string) {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}