<template>
    <div class="property">
      <div class="property-header">
        <h2 class="property-title">{{ property.name }}</h2>
      </div>
      <div class="property-body">
        <form @submit.prevent="findReservation" class="reservation-form">
          <label class="form-label">
            Room
            <input type="text" v-model.trim="room" required />
          </label>
          <label class="form-label">
            First Name
            <input type="text" v-model.trim="firstName" required />
          </label>
          <label class="form-label">
            Last Name
            <input type="text" v-model.trim="lastName" required />
          </label>
          <button class="submit-button primary-button" :disabled="!canFindReservation" @click="$event => findReservation">
            Get your Refill Pass
          </button>
          <div class="error-message">
            {{  errorMessage }}
          </div>
        </form>
      </div>
      <div class="property-footer">
        <img :src="property.logo" alt="Logo" />
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, computed, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useSearchStore } from '@/stores/search';
  import { getImageUrl } from '@/helpers/utils';
  import { apiEndpoints, fetcher } from '@/helpers/apiEndpoints';
  import type { Property } from "@/interfaces";
  
  export default defineComponent({
    name: 'PropertyView',
    setup() {
      const route = useRoute();
      const propertyId = computed(() => route.params.property_id);
      const property = ref<Property>({ name: '', logo: '', client_ref: '' });
      const room = ref('');
      const firstName = ref('');
      const lastName = ref('');
      const router = useRouter();
      let errorMessage = ref('');
  
      const canFindReservation = computed(
        () => room.value && firstName.value && lastName.value,
      );
  
      const findReservation = async () => {
        const response = await fetcher(apiEndpoints.findObjects("reservations"), {
            method: 'POST'
          },
          {
            filters: [
              {
                field: "room",
                operator: "==",
                value: room.value.toLowerCase(),
              },
              {
                field: "first_name",
                operator: "==",
                value: firstName.value.toLocaleLowerCase(),
              },
              {
                field: "last_name",
                operator: "==",
                value: lastName.value.toLocaleLowerCase(),
              },
              {
                field: "client_property_ref",
                operator: "==",
                value: property.value.client_ref,
              }
            ]
          })
        
        if (response.error) {
          // Handle the error
          console.log(response.error);
          errorMessage.value = "Could not find the reservation. Please try again, or ask the front desk for help.";
        } else {
          const data = response.data[0];
          const { id } = data;
          // Navigate to the pass page
          console.log({data});
          router.push(`/pass/reservations/${id}`);
        }
      };
  
      const fetchProperty = async () => {
        if(!propertyId.value) router.push('/search');
        const response = await fetcher(apiEndpoints.getObject("properties", propertyId.value as string));
        
        if (response.error) {
          // Handle the error
          console.log(response.error);
        } else {
          var p = response.data;
          p.logo = getImageUrl(p.logo);
          console.log({p});
          property.value = p;
        }
      };
  
      fetchProperty();
  
      return {
        property,
        errorMessage,
        room,
        firstName,
        lastName,
        canFindReservation,
        findReservation,
      };
    },
  });
  </script>
  
  <style scoped lang="scss">
  .property {
    display: flex;
    flex-direction: column;
    //height: 100%;
    align-items: center;
    justify-content: center;
    //margin: auto;
    width: 100%;
//    padding-bottom: 10vh;
  
    .property-header {
      background-color: $colorOneGreen;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      
  
      img {
        height: 70%;
        margin-right: 1rem;
      }
  
      .property-title {
        font-size: 1.2rem;
        color: white;
        text-align: center;
      }

    }

    .property-body {
      width: 80%;


    }

    .reservation-form {
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 5px;
        padding: 1rem;
        width: 100%;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        margin: 5px 0px;

        label {
          display: flex;
          flex-direction: column;
          margin: 10px 0px;
          margin-bottom: 0px;
          font-size: 1.2rem;
          font-weight: 500;
          color: $colorOneBlue;
          align-items: center;

          input {
            width: 80%;
            margin-top: 10px;
          }
        }

        .error-message {
          color: red;
          font-size: 0.9rem;
          font-weight: bold;
          text-align: center;
          width: 80%;
          margin: auto;
        }
      }

      .property-footer {
        display: flex;
        padding-top: 20px;
        //margin-top: auto;
        padding-bottom: 80px;
        width: 80%;
        margin: auto;

        img {
          max-width: 100%;
        }
      }
    
}
</style>
