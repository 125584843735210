<template>
    <div class="property-admin">
      <div v-if="propertyId && property" class="property-header">
        <div class="property-logo">
          <img v-if="property.logo" :src="property.logo" />
        </div>
        <div class="property-name">
          {{ property.name }}
        </div>
      </div>
      <h1 v-else class="title is-2">Select a Property</h1>
      <div class="route-inner">
        <div v-if="propertyId && propertySettings.reservations" class="property-options">
          <div class="action-button-group">
            <button 
              v-if="hasHydrationPlans" 
                class="button primary-button smaller" 
                @click="goTo(`/admin/property/${propertyId}/reservations/new`)">
                Create New Reservation
          </button>
            <button class="button primary-button smaller" @click="goTo(`/admin/property/${propertyId}/reservations`)">
              View Current Reservations
            </button>
          </div>
          <SearchForm 
            v-if="propertyId && property" 
            :filters="propertyFilters" 
            index="reservations" 
            title="Find Reservations" 
            :searchPlaceholder="searchPlaceholder"
            :showHydrationPlans="hasHydrationPlans"
            :smallResults="true"
            :showDates="needsDates"  
            inputAlign="left"
            class="reservations-search"
          />
        </div>
        <div v-else-if="propertyId && property && propertySettings.access_keys" class="property-options">
          <h2 class="title is-2">Access Rules</h2>
          <ul class="access-rules">
            <li v-for="(rule, index) in property.access_rules" :key="index" :class="{ 'is-editing': editingIndex === index, 'is-disabled': editingIndex !== null && editingIndex !== index }" class="access-rule">
              <div v-if="editingIndex === index && editingRule" class="editing">
                <div class="column">
                  <label for="name">Name</label>
                  <input v-model="editingRule.name" placeholder="Rule name" />
                </div>
                <div class="column">
                  <label for="keys">Keys</label>
                  <input v-model="editingRule.keys" placeholder="Keys" />
                </div>
                <div class="column">
                  <label for="credit">Credit per day</label>
                  <input type="number" v-model="editingRule.refill_credit.credit" placeholder="Refill Credit" />
                </div>
                <div class="column">
                  <label for="unlimited">Unlimited</label>
                  <input type="checkbox" v-model="editingRule.refill_credit.unlimited" />
                </div>
                <div class="button-group">
                  <button @click="editAccessRule(index)">Update</button>
                  <button @click="cancelEdit">Cancel</button>
                </div>
              </div>
              <div v-else class="not-editing">
                <div class="column">
                  <label for="name">Name</label>
                  <p>{{ rule.name }}</p>
                </div>
                <div class="column">
                  <label for="keys">Keys</label>
                  <p>{{ rule.keys }}</p>
                </div>
                <div class="column">
                  <label for="credit">Credit per day</label>
                  <p>{{ rule.refill_credit.credit }} &nbsp;</p>
                </div>
                <div class="column">
                  <label for="unlimited">Unlimited</label>
                  <p>{{ rule.refill_credit.unlimited ? "yes" : "no" }}</p>
                </div>
                <div class="button-group">
                  <button @click="startEdit(index)" :disabled="editingIndex !== null">Edit</button>
                  <button @click="startRemove(index)" :disabled="editingIndex !== null">Remove</button>
                </div>
              </div>
              <div v-if="removingIndex === index" class="overlay">
                <p>Are you sure you want to remove this rule?</p>
                <div class="button-group">
                  <button @click="confirmRemove(index)">Confirm</button>
                  <button @click="cancelRemove">Cancel</button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="access-rules">
            <div class="access-rule" :class="{ 'no-bg': !newRule }">
              <div v-if="newRule" class="editing">
                <div class="column">
                  <label>Name</label>
                  <input v-model="newRule.name" placeholder="Rule name" />  
                </div>
                <div class="column">
                  <label>Keys</label>
                  <input v-model="newRule.keys" placeholder="Keys" />
                </div>
                <div class="column">
                  <label>Credit per day</label>
                  <input type="number" v-model="newRule.refill_credit.credit" placeholder="Refill Credit" />
                </div>
                <div class="column">
                  <label>Unlimited</label>
                  <input type="checkbox" v-model="newRule.refill_credit.unlimited" />
                </div>
                <div class="button-group">
                  <button @click="saveNewRule">Save</button>
                  <button @click="cancelNewRule">Cancel</button>
                </div>
              </div>
            </div>
          </ul>
          <button @click="addAccessRule" :disabled="editingIndex !== null || newRule !== null">Add new rule</button>
        </div>
        <div v-else class="property-list">
          <div
            v-for="property in sortedProperties"
            :key="property.id"
            class="property-item"
            @click="goTo(`/admin/property/${property.id}`)"
          >
            <div class="logo">
              <img v-if="property.logo" :src="property.logo" />
            </div>
            <div class="name">{{ property.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed, watch } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { apiEndpoints, fetcher } from '@/helpers/apiEndpoints';
  import { getImageUrl } from '@/helpers/utils';
  import { useUserStore } from '@/stores/user';
  import { usePropertyStore } from "@/stores/property";
  import { each, get } from 'lodash';
  import type { AccessRules, RefillCredit, Property } from "@/interfaces"
  import SearchForm from "@/components/SearchForm.vue";
  
  export default defineComponent({
    components: {
      SearchForm
    },
    setup() {
        const route = useRoute();
        const propertyStore = usePropertyStore();
        const propertyId = computed(() => {
            const { property_id } = route.params;
            return Array.isArray(property_id) ? property_id[0] : property_id;
        });
        let property = computed(() => propertyStore.getProperty(propertyId.value));
        let propertySettings = computed(() => propertyStore.getSettings(propertyId.value));
        let properties = computed(() => propertyStore.properties);
        let sortedProperties = computed(() => {
          if(!properties.value) return [];
          return properties.value.slice().sort((a, b) => a.name.localeCompare(b.name));
        });
        let needsDates = computed(() => {
          return propertyStore.needsDates(propertyId.value);
        });
        
        // use reservation_settings.assign_hydration_plans to determine if the property has hydration plans
        let hasHydrationPlans = computed(() => {
          return propertyStore.hasHydrationPlans(propertyId.value);
        });

        const searchPlaceholder = computed(() => {
          const searchTerms = ["room", "guest name"];
          
          if (needsDates.value) {
            searchTerms.push("check-in", "check-out");
          }
          
          if (hasHydrationPlans.value) {
            searchTerms.push("hydration plans");
          }

          return `Search reservations by ${searchTerms.join(", ")}`;
        });

        let yesterdayStartOfDayUTC = computed(() => {
          let date = new Date();
          date.setUTCHours(0, 0, 0, 0);
          date.setUTCDate(date.getUTCDate() - 1);
          return Math.floor(date.getTime() / 1000);
        });

        console.log(yesterdayStartOfDayUTC.value);

        let propertyFilters = computed(() => {
          
          let filters: any = property.value && property.value.client_ref ? { facets: ["*"], facetFilters: [[`client_property_ref:${property.value.client_ref}`]]} : { facets: ["*"] };
          if(filters && needsDates.value) {
            filters['numericFilters']  = [`check_out>${yesterdayStartOfDayUTC.value}`];
          }
          return filters;
        });
        const newRule = ref<AccessRules | null>(null);

        const editingIndex = ref<number | null>(null);
        const editingRule = ref<AccessRules | null>({ 'name': '', keys: '',  refill_credit: { credit: 0, unlimited: false }});
        const removingIndex = ref<number | null>(null);

        const startEdit = (index: number) => {
          if(!property.value) return;
          editingIndex.value = index;
          editingRule.value = property.value.access_rules![index];
        };

        const cancelEdit = () => {
          editingIndex.value = null;
          editingRule.value = null;
        };

        const startRemove = (index: number) => {
          removingIndex.value = index;
        };

        const cancelRemove = () => {
          removingIndex.value = null;
        };

        const confirmRemove = async (index: number) => {
          await propertyStore.removeAccessRule(propertyId.value, index);
          removingIndex.value = null;
        };

        const reservationId = ref("");
        const errorMessage = ref("");
        const room = ref("");
        const firstName = ref("");
        const lastName = ref("");
        const router = useRouter();
        const userStore = useUserStore();
        // const account = computed(() => userStore.account);
        // const isSuperuser = computed(() => userStore.isSuperuser);
  
        const findReservation = async () => {
        try {
          const filters = [];

          if (reservationId.value) {
            filters.push({
              field: "reservation_id",
              operator: "==",
              value: reservationId.value.toLowerCase(),
            });
          }

          if (room.value) {
            filters.push({
              field: "room",
              operator: "==",
              value: room.value.toLowerCase(),
            });
          }

          if (firstName.value) {
            filters.push({
              field: "first_name",
              operator: "==",
              value: firstName.value.toLowerCase(),
            });
          }

          if (lastName.value) {
            filters.push({
              field: "last_name",
              operator: "==",
              value: lastName.value.toLowerCase(),
            });
          }

          const reservationResponse = await fetcher(
            apiEndpoints.findObjects("reservations"),
            {
              method: "POST",
            },
            {
              filters,
            }
          );

          if (reservationResponse && reservationResponse.data) {
            errorMessage.value = "";
            var reservation = reservationResponse.data[0];
            router.push(
              `/admin/property/${propertyId.value}/reservations/${reservation.id}`
            );
          } else {
            errorMessage.value = "Reservation not found";
          }
        } catch (error) {
          errorMessage.value = "Error fetching reservation";
        }
      };
 
      const addAccessRule = () => {
        newRule.value = {
          name: '',
          keys: [],
          refill_credit: {
            credit: 0,
            unlimited: false,
          }
        };
      };

      const saveNewRule = () => {
        if(!newRule.value) return;
        propertyStore.addAccessRule(propertyId.value, newRule.value);
        newRule.value = null;
      };

      const cancelNewRule = () => {
        newRule.value = null;
      };

      const editAccessRule = async (index: number) => {
        if(!property.value) return;
        const rule = property.value.access_rules![index];
        await propertyStore.editAccessRule(propertyId.value, index, rule);
        editingIndex.value = null;
      };

      const removeAccessRule = async (index: number) => {
        await propertyStore.removeAccessRule(propertyId.value, index);
      };
      
      const goTo: (path: string) => void = (path) => {
        router.push(path);
      };

      const start: () => void = async () => {
       // propertyStore.fetchProperty(propertyId.value);
  
        if (!propertyId.value) {
          console.log("getting all properties")
          propertyStore.fetchUserProperties();
        } else {
          // Implement your logic here to fetch the property
          console.log("getting property", propertyId.value);
          propertyStore.fetchProperty(propertyId.value);
        }

      };

      // Watch for route changes
      watch(
        () => route.params,
        () => {
          start();
        },
        { deep: true }
      );

      start();
      
  
      return {
        property,
        propertyId,
        reservationId,
        errorMessage,
        properties,
        sortedProperties,
        needsDates,
        hasHydrationPlans,
        findReservation,
        goTo,
        room,
        firstName,
        lastName,
        propertySettings,
        newRule,
        addAccessRule,
        saveNewRule,
        cancelNewRule,
        editAccessRule,
        removeAccessRule,
        editingIndex,
        editingRule,
        startEdit,
        cancelEdit,
        removingIndex,
        startRemove,
        cancelRemove,
        confirmRemove,
        propertyFilters,
        searchPlaceholder
      };
    },
  });
  </script>
  
<style scoped lang="scss">
  .property-admin {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .property-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      .property-logo {
        max-width: 300px;
        margin-bottom: 20px;
        height: 100px;

        img {
          //width: 100%;
          height: 100%;
        }
      }
      
      .property-name {
        font-size: 1rem;
        font-weight: bold;
        color: $colorOneBlue;
      }

    }
  }
  

  .property-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .title {
      margin: 30px auto;
    }

    .access-rules {
      width: 80%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .access-rule {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgba(white, 0.9);
        border-radius: 3px;
        padding: 10px;
        width: 100%;
        position: relative;

        &.no-bg {
          background-color: transparent;
        }

        .not-editing, .editing {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          flex: 1;
          position: relative;


          .column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            flex: 1;
            height: 100%;


            label {
              font-size: 0.7rem;
              color: rgba($colorOneBlue, 0.5);
              margin-bottom: 5px;
              font-weight: bold;
            }

            input, p {
              font-size: 1rem;
              color: rgba($colorOneBlue, 0.9);
              //margin-bottom: 0.5rem;
              font-weight: bold;
              margin: auto;
              text-align: left;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
              width: 100%;
            }

            input {
              text-align: center;
              width: 90%;

              //type checkbox 
              &[type=checkbox] {
                height: 30px;
                /* flex: 1; */
                font-size: 1rem;
                margin: 5px;
                align-items: flex-start;
                justify-content: flex-start;
                text-align: left;
                width: 30px;
              }
            }

            p {
              font-size: 0.8rem;
            }

          }


        }

        .overlay {
            position: absolute;
            display: flex;
            flex-direction: row;
            background-color: rgba(white, 0.9);
            width: 100%;
            align-items: center;
            justify-content: center;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding: 10px;

            p {
              flex: 1;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center; 
              font-weight: bold;

            }
        }

        .button-group {
          margin-left: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
        }
      }

    }

    .reservations-search {
      max-width: 80%;
    }
  }
  
  .property-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  
  .property-item {
    background-color: white;
        border-radius: 3px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        margin: 10px;
        margin-bottom: 20px;
        padding: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        width: 100%;
        position: relative;

        .name {
            font-weight: bold;
            font-size: 1.2rem;
            margin: 10px 0px;
            text-align: center;
            width: 80%;
            margin-top: 20px;
            
        }

        .logo {
            width: 70%;
            height: auto;
            margin-top: 10px;
            margin-bottom: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            

            img {
              max-height: 80%;
              max-width: 80%;
            }
        }
        &:last-child {
          margin-bottom: 100px;
        }

        @media screen and (min-width: 768px) {
            width: 20vw;
            height: 20vh;

            .logo {
              height: 60%;
              display: flex;
              flex: 1;
            }

            .name {
              font-size: 0.8rem;
              margin-top: 0px;
            }

            &:last-child {
              margin-bottom: 20px;
            }
        }

        

  }
  
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    //width: 50%;
    min-width: 300px;
    //max-width: 350px;

    .row-on-desktop {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  
  .find-reservation {
    gap: 1rem;
  }
  
  .error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0
}

.action-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

button {
  padding: 0.5rem 1rem;
  background-color: $kmzBlue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #0056b3;
  }
}
</style>  