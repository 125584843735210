<template>
    <div id="home-container">
      <div class="modules">
        <div v-for="(module, index) in modules" :key="'module-' + index" class="module" :class="[positionClasses[index]]">
          <div class="module-inner" :class="[module.name + '-wrapper']">
            <div v-if="module.name === 'video-module'" :class="module.name">
              <iframe src="https://player.vimeo.com/video/826430076?h=d98c4c9121&autoplay=1&loop=1&color=aaffc2&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div class="video-poster">
                <img :src="videoPoster" alt="video poster" />
                <div class="play-button">
                  <font-awesome-icon icon="play-circle" />
                </div>
              </div>
              <div class="lightbox-overlay" v-if="lightboxVisible">
                <div class="lightbox-background" @click="hideLightbox"></div>
                <iframe class="lightbox-video" src="https://player.vimeo.com/video/826430076?h=d98c4c9121&autoplay=1&loop=1&color=aaffc2&title=0&byline=0&portrait=0&autoplay=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                <div class="lightbox-close" @click="hideLightbox">Close</div>
              </div>
            </div>
            <div v-if="module.name === 'cta-module'" :class="[module.name]">
              <a href="https://aguakmzero.com/contact" class="cta-title" target="_blank">
                Join the Movement
                <font-awesome-icon icon="arrow-right" />
              </a>
            </div>
            <div v-if="module.name === 'circuit-module'" :class="[module.name]">
              <div class="module-header">
                <div class="header-title">
                  Refill stations near you
                </div>
                <div class="header-icon">
                  <img :src="locationPinIcon" alt="location pin icon" />
                </div>
              </div>
              <div class="image" :style=" { backgroundImage: 'url(' + waterCircuit + ')'}">
              </div>
              <div class="circuitry">
                <div class="coming-soon-tag">
                    Coming soon
                </div>
                <div class="circuitry-title-group">
                  <div class="circuit-title">
                    Discover the circuit
                  </div>
                </div>
                <div class="location-tags">
                  <div v-for="(location, index) in locations" :key="'location-' + index" class="location-tag">
                    {{ location }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="module.name === 'impact-module'" :class="[module.name]">
              <div class="module-header">
                <div class="header-icon">
                  <img :src="leafIcon" alt="leaf icon" />
                </div>
                <div class="header-title">
                  This is what we are doing for the planet
                </div>
              </div>
              <div class="impact-text">
                <div class="impact-number">
                  {{ impactNumber }}
                  <!-- <div class="impact-unit">
                    bottelas de plastico
                  </div> -->
                </div>
                <div class="impact-caption">
                  de plástico menos en el mar
                </div>
              </div>
              <div class="impact-cta">
                <a href="https://aguakmzero.com" class="cta-title" target="_blank">
                  This is how we do it
                </a>
                <div class="cta-arrow">
                  <img :src="longRightArrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  // import SearchForm from "@/components/SearchForm.vue";
  import logoDefault from '@/assets/logos/logo.svg';
  import videoPoster from "@/assets/images/kmzero-video-poster-1.png";
  import locationPinIcon from "@/assets/images/location-pin@3x.png";
  import leafIcon from "@/assets/images/leaf-icon@3x.png";
  import longRightArrow from "@/assets/images/long-right-arrow@3x.png";
  import waterCircuit from "@/assets/images/water-tibidabo.png";
  // import VueOdometer from "node_modules/v-odometer/dist/main.prod";


  export default defineComponent({
    // components: {
    //   'vue-odometer': VueOdometer,
    // },
    setup() {
      const title = "Let's find a refill point";
      const searchPlacholder = "Where are you?";
      const index = "properties";
      const filters = { facets: ["*"], facetFilters: [['active:true']] }

      const kmzlogo = logoDefault;

      const locations = ["Barcelona", "Madrid", "Palma de Mallorca", "Menorca", "Andorra"];

      const positionClasses = ["hero top-row", "thin-button top-row", "left-one-thirds bottom-row", "right-two-thirds bottom-row"];

      const Vimeo = (window as any).Vimeo;

      // New reactive property to track lightbox visibility
      const lightboxVisible = ref(false);

      // New method to show the lightbox
      const showLightbox = () => {
        lightboxVisible.value = true;
      };

      // New method to hide the lightbox
      const hideLightbox = () => {
        lightboxVisible.value = false;
      };

      const impactNumber = ref(0);
      

      const modules = [
        { 
          name: "video-module"
        },
        {
          name: "cta-module"
        },
        {
          name: "circuit-module"
        },
        {
          name: "impact-module"
        }
      ]
  
      return {
        impactNumber,
        lightboxVisible,
        showLightbox,
        hideLightbox,
        Vimeo,
        locations,
        positionClasses,
        modules,
        kmzlogo,
        title,
        searchPlacholder,
        index,
        filters,
        videoPoster,
        locationPinIcon,
        leafIcon,
        longRightArrow,
        waterCircuit
      };
    },
    methods: {
      setupVimeo() {

        if(!this.Vimeo) return null;

        const iframe = document.querySelector('iframe');
        const player = new this.Vimeo.Player(iframe);
        const videoPoster = document.querySelector('.video-poster');
        const playButton = document.querySelector('.play-button');
        const videoModule = document.querySelector('.video-module');

        if(!player || !videoPoster || !playButton || !videoModule) return null;

        playButton.addEventListener('click', () => {
          //videoPoster.classList.add('playing');
          player.play();
          this.showLightbox();  
        });

        player.on('play', () => {
          //videoPoster.classList.add('playing');
        });

        player.on('pause', () => {
          //videoPoster.classList.remove('playing');
        });

        player.on('ended', () => {
          //videoPoster.classList.remove('playing');
        });

        const closeButton = document.querySelector('.lightbox-close');
        if (closeButton) {
          closeButton.addEventListener('click', () => {
            this.hideLightbox();  // Hide the lightbox
          });
        }

        // videoModule.addEventListener('mouseenter', () => {
        //   videoPoster.classList.remove('playing');
        // });

        // videoModule.addEventListener('mouseleave', () => {
        //   videoPoster.classList.add('playing');
        // });
      }
    },
    // on mount add "home" class to body
    async mounted() {
      document.body.classList.add("scrollable");
      this.setupVimeo();

      // Fetch data from Firestore via Google Cloud Function
      try {
        const response = await fetch('https://us-central1-h2os-global.cloudfunctions.net/getRefillStats');
        const data = await response.json();
        this.impactNumber = Math.round(data.bottles) || 0;
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
      
    },
    // on unmount remove "home" class from body
    beforeUnmount() {
      document.body.classList.remove("scrollable");
    }
  });
  </script>

  
<style scoped lang="scss">



  #home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding: 20px;
    justify-content: start;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;

  }

  .modules {
    display: flex;
    width: 100%;
    height: 100%;
    //background-color: rgba($colorOneGreen, 0.1);
    flex-wrap: wrap;
    margin-top: -1vw;


    .module {
      //background-color: rgba($colorOneGreen, 0.2);
      border-radius: 20px;
      padding: 1vh;

      .module-inner {
        background-color: rgba($colorOneGreen, 0.1);
        display: flex;
        height: 100%;
        width: 100%;
        border-radius: 25px;
        overflow: hidden;

        .video-module {
          width: 100%;
          position: relative;
          height: 100%;

          iframe {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
          }

          .video-poster {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            display: flex;
            background-color: $logoDarkColor;
            justify-content: center;
            align-items: center;

            img {
              position: absolute;
              top: 0;
              right: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              opacity: 0.9;
            }

            .play-button {
              position: relative;
              font-size: 5rem;
              color: $colorOneGreen;
              cursor: pointer;
              transition: all 0.5s ease;

              &:hover {
                transform: scale(1.1);

              }
            }

            &.playing {
              background-color: transparent;

              img {
                display: none;
              }

              .play-button {
                display: none;
              }
            }

          }

          .lightbox-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            z-index: 9999;
            display: flex;
            justify-content: center;
            align-items: center;
            
            .lightbox-background {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.4);
              z-index: 10;
            }

            .lightbox-video {
              width: 80%;
              height: 80%;
              position: relative;
              z-index: 11;
            }
            
            .lightbox-close {
              position: absolute;
              top: 10px;
              right: 10px;
              cursor: pointer;
              color: white;
            }
          }


        }

        .cta-module {
          background-color: $colorOneBlue;
          width: 100%;
          height: 100%;
          color: white;
          font-size: 2rem;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;

          .cta-title {
            transform: rotate(-90deg);
            //width: 100%;
            white-space: nowrap;
            font-size: 1.5rem;
            transform-origin: center center;
            letter-spacing: 0px;
            color: white;
            text-decoration: none;
            
            .svg-inline--fa {
              margin-left: 5px;
              transform: rotate(45deg);
            }

            &:hover, &:visited, &:active {
              color: white;
              text-decoration: none;
            }
          }
        }

        .circuit-module {
          background-color: $colorOneLightBlue;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          padding: 20px;
          padding-right: 0px;
          position: relative;

          .module-header {
            position: absolute;
            right: 15px;
            top: 15px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .header-title {
              font-size: 0.65rem;
              text-transform: uppercase;
              width: 50%;
              margin-right: 10px;
              text-align: right;
            }

            .header-icon {
              width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            
          }

          .image {
            width: 40%;
            max-width: 200px;
            height: 200px;
            object-fit: cover;
            background-color: rgba(white, 0.2);
            border-radius: 20px;
            overflow: hidden;
            display: flex;
            background-position: center;
            background-size: cover;
          }

          .circuitry {
            display: flex;
            flex: 1;
            width: 60%;
            height: 100%;
            //background-color: rgba(white, 0.2);
            padding: 10px 20px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            align-content: flex-end;
            overflow: hidden;

            .coming-soon-tag {
                background-color: rgba($textColorDarkTwo, 0.3);
                padding: 5px 10px;
                border-radius: 20px;
                font-size: 0.6rem;
                text-transform: uppercase;
                display: flex;
                margin-bottom: 7px;
                height: fit-content;
                color: white;
                font-weight: bold;
                
            }
            .circuitry-title-group {
              width: 100%;
              display: flex;
              justify-content: flex-start;

              .circuit-title {
                font-size: 1.2rem;
                font-weight: bold;
                margin-bottom: 10px;
                color: $textColorDarkTwo;
                width: 60%;
                max-width: 150px;
              }
            }

            .location-tags {
              display: flex;
              flex-wrap: nowrap;
              overflow: hidden;
              overflow-x: scroll;
              max-width: 120%;
              padding-right: 50px;
              // no scroll bar on any platform
              scrollbar-width: none;
              // Firefox
              -ms-overflow-style: none;
              // Safari and Chrome
              &::-webkit-scrollbar {
                display: none;
              }


              .location-tag {
                font-size: 0.6rem;
                text-transform: uppercase;
                border: solid 1px $textColorDarkTwo;
                border-radius: 20px;
                display: flex;
                padding: 0.5rem 1rem;
                flex-wrap: nowrap;
                white-space: nowrap;
                margin-right: 10px;
              }
              
            }
          }
        }

        .impact-module {
          background-color: $colorOneGreen;
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .module-header {
            position: absolute;
            left: 15px;
            top: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .header-title {
              font-size: 0.65rem;
              text-transform: uppercase;
              width: 50%;
              margin-left: 10px;
              text-align: left;
            }

            .header-icon {
              width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            
          }

          .impact-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .impact-number {
              font-size: 2.5rem;
              font-weight: bold;
              display: flex;
              align-items: flex-end;
              .impact-unit {
                font-size: 1rem;
                margin-bottom: 8px;
                margin-left: 5px;
              }
            }

            .impact-caption {
              font-size: 1.6rem;
              font-weight: bold;
              text-align: center;
              width: 70%;
              line-height: 1.5rem;
            }
          }

          .impact-cta {
            position: absolute;
            bottom: 15px;
            right: 15px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .cta-title {
              font-size: 0.8rem;
              text-transform: uppercase;
              margin-right: 10px;
            }

            a {
              color: rgba($textColorDarkTwo, 1);
              text-decoration: none;
            }

            .cta-arrow {
              //width: 15%;
              height: 1rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

      }

      &.hero {
        width: 90%;
      }

      &.thin-button {
        width: 10%;
        //max-width: 130px;
        min-width: 100px;
        padding-right: 0px;
      }

      &.left-one-thirds {
        width: 40%;
      }

      &.right-two-thirds {
        width: 60%;
        padding-right: 0px;
      }

      &.top-row {
        height: 45vh;
        max-height: 600px;
        min-height: 350px;
      }

      &.bottom-row {
        height: 30vh;
        max-height: 450px;
        min-height: 250px;
      }


    }
  }

  // css media queries if screen height is more than screen width
  @media screen and (max-aspect-ratio: 1.2/1) {

    .modules {
      flex-direction: column;
      flex-wrap: unset;
      min-height: 100%;
      //overflow: hidden;
      //overflow-y: scroll;


      .module {
        width: 100%;
        

        &.hero, .thin-button, .left-one-thirds, .right-two-thirds {
          width: 100%;
        }

        &.top-row, &.bottom-row {
          height: unset;
          min-height: 40px;
          max-height: unset;
        }

        &.hero {
          height: 30vh;
          margin-bottom: 2vh;
        }

        &.thin-button {
          // height: 50px;
          width: 100%;
          max-width: unset;
          min-width: unset;
          height: 100px;
        }

        &.left-one-thirds, &.right-two-thirds {
          width: 100%;
          height: 30vh;
          margin-bottom: 2vh;
          padding-right: 0px;
        }

        .module-inner {

          .video-module {
            //height: 40vh;
          }
          .cta-module {
            padding: 20px;
            .cta-title {
              transform: rotate(0deg);
              font-size: 1.5rem;
              .svg-inline--fa {
                margin-left: 0px;
                transform: rotate(0deg);
              }
            }
          }

          &.video-module-wrapper, &.circuit-module-wrapper, &.impact-module-wrapper {
            //height: auto;
            //height: 40vh;
            max-height: unset;
            height: 30vh;
          }
        }

      }
    }
  }
 

</style>
  
